import Styles from './AddVoucherForm.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  FormGroup,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material'
import { object, string } from 'yup'
import { IFLoadingIndicator, IFButton } from 'Components'
import VoucherSelectors from 'Stores/Voucher/Selectors'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { DatePicker } from 'rsuite'
import { useEffect, useState } from 'react'
import { styled as muiStyled } from '@mui/system'
import AuthSelectors from 'Stores/Auth/Selectors'
import ReactFlagsSelect from 'react-flags-select'
import PostAddIcon from '@mui/icons-material/PostAdd'
import shortUUID from 'short-uuid'

const StyledTextField = muiStyled(TextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
})

const initialValues = {
  voucherName: '',
  voucherCode: '',
  voucherPoints: '',
  voucherPoints: '',
  voucherMaxRedeemCountPerUser: '',
  voucherMaxRedeemed: '',
  voucherValidity: '',
  country: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`

const AddVoucherForm = ({ onSubmitForm, isAddVoucherLoading, admin }) => {
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState(null)
  const [countryInputFiled, setCountryInputFiled] = useState(false)
  const [selected, setSelected] = useState()
  const [prefix, setPrefix] = useState()

  useEffect(() => {
    if (admin.registeredCountries.length > 1) {
      setCountryInputFiled(true)
      setSelected(admin.registeredCountries[0].code)
    }
    if (admin.registeredCountries) {
      setSelected(admin.registeredCountries[0].code)
      setPrefix(admin.registeredCountries[0].code + '-')
    }
  }, [admin])

  useEffect(() => {
    setPrefix(selected + '-')
  }, [selected])
  const countries = admin.registeredCountries.map((item) => item.code)
  const customLabels = admin.registeredCountries.reduce((labels, item) => {
    labels[item.code] = item.name
    return labels
  }, {})
  return (
    <FormContainer className={Styles.FormWrappingDiv}>
      <div>
        <Formik
          validationSchema={object({
            voucherName: Yup.string()
              .min(3, t('VouchersPage.MinChars', { count: 3 }))
              .max(50, t('VouchersPage.MaxChars', { count: 50 }))
              .required(t('VouchersPage.Required')),
            voucherCode: string().required(t('VouchersPage.Required')),
            voucherPoints: Yup.number().required(t('VouchersPage.Required')),
            voucherMaxRedeemed: Yup.number().required(
              t('VouchersPage.Required'),
            ),
            voucherValidity: string().required(t('VouchersPage.Required')),
          })}
          initialValues={{
            ...initialValues,
            voucherExpiry: selectedDate
              ? selectedDate.toISOString().split('T')[0]
              : undefined,
            country: admin.registeredCountries
              ? admin?.registeredCountries[0].code
              : '',
          }}
          onSubmit={(values) => {
            const updatedValues = {
              ...values,
              voucherCode: `${prefix}${values.voucherCode}`,
            }
            onSubmitForm(updatedValues)
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherName">
                  {t('VouchersPage.VoucherNameForm')}
                </label>
                <Field
                  className={Styles.Input}
                  size="small"
                  name="voucherName"
                  as={StyledTextField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherName" />
                </ErrorContainer>
              </FormGroup>
              {countryInputFiled && (
                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="country">{t('VouchersPage.Country')}</label>
                  <div>
                    <ReactFlagsSelect
                      countries={countries}
                      customLabels={customLabels}
                      showSelectedLabel={true}
                      selectedSize={14}
                      optionsSize={14}
                      fullWidth={true}
                      selected={selected}
                      onSelect={(code) => {
                        setSelected(code)
                        setFieldValue('country', code)
                      }}
                      selectButtonClassName={Styles.ReactFlagsSelect}
                    />
                  </div>
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="country" />
                  </ErrorContainer>
                </FormGroup>
              )}
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherCode">
                  {t('VouchersPage.VoucherCodeForm')}
                </label>
                <Field
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherCode"
                  size="small"
                  variant="outlined"
                  id="outlined-start-adornment"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{prefix}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Generate Code">
                          <IconButton
                            onClick={() => {
                              const generatedCode = shortUUID.generate()
                              setFieldValue('voucherCode', generatedCode)
                            }}
                            edge="end"
                          >
                            <PostAddIcon sx={{ color: Colors.gray }} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherCode" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherPoints">
                  {t('VouchersPage.VoucherPointsForm')}
                </label>
                <Field
                  type="number"
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherPoints"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 1 }}
                  validate={(value) => {
                    if (value <= 0) return t('VouchersPage.PositiveNumber')
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherPoints" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherMaxRedeemed">
                  {t('VouchersPage.VoucherMaxRedeemedForm')}
                </label>
                <Field
                  type="number"
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherMaxRedeemed"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 1 }}
                  validate={(value) => {
                    if (value <= 0) return t('VouchersPage.PositiveNumber')
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherMaxRedeemed" />
                </ErrorContainer>
              </FormGroup>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherMaxRedeemCountPerUser">
                  {t('VouchersPage.VoucherMaxRedeemCountPerUser')}
                </label>
                <Field
                  type="number"
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherMaxRedeemCountPerUser"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 1 }}
                  validate={(value) => {
                    if (value <= 0) return t('VouchersPage.PositiveNumber')
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherMaxRedeemCountPerUser" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherValidity">
                  {t('VouchersPage.ValidityForm')}
                </label>
                <Field
                  type="number"
                  name="voucherValidity"
                  variant="outlined"
                  size="small"
                  as={StyledTextField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 1 }}
                  validate={(value) => {
                    if (value <= 0) return t('VouchersPage.PositiveNumber')
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherValidity" />
                </ErrorContainer>
              </FormGroup>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherExpiry">
                  {t('VouchersPage.ExpiryForm')}
                </label>
                <DatePicker
                  placement="top"
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue)
                    setFieldValue(
                      'voucherExpiry',
                      newValue ? newValue.toISOString().split('T')[0] : null,
                    )
                  }}
                  cleanable={false}
                  size="lg"
                  placeholder="YYYY-MM-DD"
                  shouldCloseOnSelect={false}
                  disabledDate={(date) => date < new Date()}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherExpiry" />
                </ErrorContainer>
              </FormGroup>
              <div className={Styles.ButtonDiv}>
                <IFButton
                  className={Styles.ButtonWrappingDiv}
                  type="submit"
                  color={Colors.primary}
                  isDead={
                    !(
                      values.voucherName.length > 0 &&
                      values.voucherCode.length > 0 &&
                      values.voucherPoints &&
                      values.voucherMaxRedeemed &&
                      values.voucherValidity
                    ) ||
                    (errors.voucherName && errors.voucherName.length > 0) ||
                    (errors.voucherCode && errors.voucherCode.length > 0) ||
                    (errors.voucherPoints && errors.voucherPoints.length > 0) ||
                    (errors.voucherMaxRedeemed &&
                      errors.voucherMaxRedeemed.length > 0) ||
                    (errors.voucherValidity &&
                      errors.voucherValidity.length > 0)
                  }
                  isFill={true}
                  isLoading={false}
                  text={
                    isAddVoucherLoading ? (
                      <div className={Styles.LoadingIndicator}>
                        <IFLoadingIndicator size={'2em'} />
                      </div>
                    ) : (
                      t('VouchersPage.Submit')
                    )
                  }
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormContainer>
  )
}

const mapStateToProps = (state) => ({
  isAddVoucherLoading: VoucherSelectors.getIsAddVoucherLoading(state),
  admin: AuthSelectors.getUser(state),
})

export default connect(mapStateToProps)(AddVoucherForm)
