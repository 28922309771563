import PropTypes from 'prop-types'
import Styles from './AdminPageTab.module.css'
import { IFSkeleton, IFText } from 'Components'
import { Colors } from 'Theme'
import RequestState from 'Enums/RequestState'
const AdminPageTab = ({ title, count, isSelected, fetchListRequestState }) => {
  return (
    <div className={Styles.Container}>
      <IFText
        style={{ color: isSelected ? Colors.text : Colors.gray }}
        className={Styles.AdminPageTabTitle}
      >
        {title}
      </IFText>
      <div
        className={Styles.CountDiv}
        style={{
          backgroundColor: isSelected
            ? fetchListRequestState === RequestState.LOADING
              ? Colors.grey
              : Colors.primaryLight
            : Colors.grey,
        }}
      >
        {fetchListRequestState === RequestState.LOADING && isSelected ? (
          <IFSkeleton variant="circular" />
        ) : (
          <IFText
            style={{
              color: isSelected ? Colors.text : Colors.gray,
              fontSize: 12,
            }}
          >
            {count}
          </IFText>
        )}
      </div>
    </div>
  )
}
AdminPageTab.propTypes = {
  title: PropTypes.string,
  count: PropTypes.string,
  isSelected: PropTypes.bool,
}
export default AdminPageTab
