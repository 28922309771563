import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  IFTable,
  IFText,
  IFsvg,
  IFTooltipIconsLoading,
  IFButton,
} from 'Components'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Skeleton from '@mui/material/Skeleton'
import { Colors } from 'Theme'
import Styles from './AdminInvitationsList.module.css'
import { Tooltip, IconButton, Box } from '@mui/material'
import { capitalizeFirstLetter, formatDate } from '../../Utils/StringFunctions'
import AuthSelectors from 'Stores/Auth/Selectors'
import AdminSelectors from 'Stores/Admin/Selectors'
import AdminActions from 'Stores/Admin/Actions'
import IFDialog from 'Components/IFDialog/IFDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import RoleActions from 'Stores/Role/Actions'
import AdminPermissions from 'Enums/InfinityEnums'
import ReportIcon from '@mui/icons-material/Report'

const AdminInvitationsList = ({
  inviteAdmins,
  fetchInviteAdminListRequestState,
  tablesHeight,
  deleteInviteAdminRequestState,
  sendAdminEmailRequestState,
  sendAdminEmail,
  deleteInviteAdmin,
  fetchRoles,
  loggedInAdmin,
  editingAdminRole,
  inviteAdminPaginationOffset,
  fetchInviteAdminList,
}) => {
  const [failedRequest, setFailedRequest] = useState(false)
  const { t } = useTranslation()
  const [currentResendRow, setCurrentResendRow] = useState(null)
  const confirmDeleteInviteAdminDialog = useRef()
  const [deleteInviteAdminId, setDeleteInviteAdminId] = useState(null)
  const [registeredCountries, setRegisteredCountries] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    country: false,
  })
  const canEditAdmin = editingAdminRole.includes(
    AdminPermissions.AdminPermissions.CAN_INVITE_ADMIN,
  )
  const onResendInviteClickHandler = (index) => {
    const rowId = inviteAdmins[index]._id

    setCurrentResendRow(rowId)

    sendAdminEmail(inviteAdmins[index].email)
  }

  const onDeleteInviteAdminClickHandler = (index) => {
    setDeleteInviteAdminId(inviteAdmins[index]._id)
    confirmDeleteInviteAdminDialog.current.show()
  }
  const handleDeleteInviteAdmin = () => {
    deleteInviteAdmin(deleteInviteAdminId)

    setDeleteInviteAdminId(null)
  }
  useEffect(() => {
    if (loggedInAdmin?.registeredCountries?.length > 1) {
      setRegisteredCountries(true)
    }
    fetchRoles()
  }, [loggedInAdmin])

  useEffect(() => {
    setColumnVisibility({
      country: registeredCountries,
    })
  }, [registeredCountries])
  useEffect(() => {
    if (deleteInviteAdminRequestState === RequestState.SUCCEEDED) {
      confirmDeleteInviteAdminDialog.current.dismiss()
    }
  }, [deleteInviteAdminRequestState])

  const AdminsInvitationsTableColumns = useMemo(
    () => [
      {
        accessorKey: 'email',
        header:
          fetchInviteAdminListRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AdminList.Email')}</IFText>
          ),
        Cell: ({ row }) => {
          return row.index < inviteAdmins.length ? (
            <div>
              <div>
                <IFText>{capitalizeFirstLetter(row.original.email)}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },

      {
        accessorKey: 'country',
        header:
          fetchInviteAdminListRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('InvitationsList.Country')}</IFText>
          ),
        Cell: ({ row }) => {
          let countryCodes = []
          if (row.index < inviteAdmins.length) {
            countryCodes = row.original.registeredCountries
              .map((country) => country.name)
              .join(', ')
          }

          return row.index < inviteAdmins.length ? (
            <div>
              <div>
                <IFText>{countryCodes}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'createdOn',
        header:
          fetchInviteAdminListRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <div className={Styles.AdminColumnCreatedOn}>
              {t('AdminList.CreatedOn')}
            </div>
          ),
        Cell: ({ row }) => {
          return row.index < inviteAdmins.length ? (
            <div>
              <div>
                <IFText>{formatDate(row.original.createdAt)}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'role',
        header:
          fetchInviteAdminListRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AdminList.Role')}</IFText>
          ),
        Cell: ({ row }) => {
          return row.index < inviteAdmins.length ? (
            <div>
              <div>
                <IFText>{row.original.role.name}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [inviteAdmins, fetchInviteAdminListRequestState, failedRequest],
  )

  const renderRowActions = ({ row }) => {
    if (Object.keys(row.original).length > 0) {
      const isLoading =
        sendAdminEmailRequestState === RequestState.LOADING &&
        currentResendRow === row.original._id
      return (
        <Box>
          <div className={Styles.TableIcons}>
            <Tooltip title={t('InvitationsList.ResendInviteAdminEmail')}>
              <div className={Styles.CustomBackGround}>
                <IFTooltipIconsLoading
                  isLoading={isLoading}
                  Icon={IFsvg.ResendEmailIcon}
                  FilledIcon={IFsvg.ResendEmailIcon}
                  size={24}
                  animationDisabled={false}
                  onClick={() => {
                    if (!isLoading) onResendInviteClickHandler(row.index)
                  }}
                />
              </div>
            </Tooltip>

            <Tooltip title={t('InvitationsList.deleteModalConfirm')}>
              <IconButton
                onClick={() => {
                  onDeleteInviteAdminClickHandler(row.index)
                }}
              >
                <div
                  className={Styles.DeleteIcon}
                  style={{ color: Colors.AdminListDeleteIcon }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      )
    }
    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={handleRetry}
        />
      )
    }
  }
  const loadMoreData = () => {
    if (
      fetchInviteAdminListRequestState === RequestState.LOADING ||
      inviteAdminPaginationOffset === null
    )
      return
    fetchInviteAdminList(inviteAdminPaginationOffset, failedRequest)
  }
  useEffect(() => {
    if (
      fetchInviteAdminListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchInviteAdminListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchInviteAdminListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchInviteAdminListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchInviteAdminListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchInviteAdminListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchInviteAdminListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchInviteAdminListRequestState])
  const handleRetry = () => {
    fetchInviteAdminList(inviteAdminPaginationOffset)
  }
  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  return (
    <>
      <IFTable
        columns={AdminsInvitationsTableColumns}
        data={inviteAdmins}
        tableMaxHeight={tablesHeight}
        tableSetHeight={'100%'}
        showSkeleton={fetchInviteAdminListRequestState === RequestState.LOADING}
        tableRequestState={fetchInviteAdminListRequestState}
        enableEditing={canEditAdmin}
        renderRowActions={renderRowActions}
        hideColumnQuery={columnVisibility}
        fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        paginationOffset={inviteAdminPaginationOffset}
      />
      <IFDialog
        ref={confirmDeleteInviteAdminDialog}
        style={{ margin: '15em' }}
        open={false}
        title={t('AdminList.deleteModalTitle')}
        bodyText={t('InvitationsList.deleteInviteAdminModalText')}
        buttonAcceptonClick={handleDeleteInviteAdmin}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('AdminList.deleteModalConfirm')}
        buttonClassName={Styles.Button}
        isLoading={deleteInviteAdminRequestState === RequestState.LOADING}
        dismissOnAccept={false}
      />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    deleteInviteAdmin: (inviteAdminId) =>
      dispatch(AdminActions.deleteInviteAdmin(inviteAdminId)),
    sendAdminEmail: (email) => dispatch(AdminActions.sendAdminEmail(email)),
    fetchRoles: () => dispatch(RoleActions.fetchRoles()),
    fetchInviteAdminList: (offset, shouldShowError) =>
      dispatch(AdminActions.fetchInviteAdminList(offset, shouldShowError)),
  }
}
const mapStateToProps = (state) => ({
  inviteAdmins: AdminSelectors.getInviteAdmins(state),
  fetchInviteAdminListRequestState:
    AdminSelectors.getFetchInviteAdminListRequestState(state),
  deleteInviteAdminRequestState:
    AdminSelectors.getDeleteInviteAdminRequestState(state),
  sendAdminEmailRequestState:
    AdminSelectors.getSendAdminEmailRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  inviteAdminPaginationOffset:
    AdminSelectors.getInviteAdminListPaginationOffset(state),
})

AdminInvitationsList.propTypes = {
  inviteAdmins: PropTypes.array,
  fetchInviteAdminListRequestState: PropTypes.string,
  tablesHeight: PropTypes.string,
  deleteInviteAdminRequestState: PropTypes.string,
  sendAdminEmailRequestState: PropTypes.string,
  sendAdminEmail: PropTypes.func,
  deleteInviteAdmin: PropTypes.func,
  fetchRoles: PropTypes.func,
  loggedInAdmin: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminInvitationsList)
