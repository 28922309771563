import styles from './TransactionSessionDetail.module.css'
import { IFButton, IFText } from 'Components'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import InfinityEnums from 'Enums/InfinityEnums'
import PropTypes from 'prop-types'
import { formatDate } from '../../Utils/StringFunctions'

const TransactionSessionDetail = ({
  row,
  onReverseClick,
  editingAdminRole,
}) => {
  const getTimeBetweenDates = (firstDate, secondDate) => {
    var firstMomentDate = moment(firstDate)
    var secondMomentDate = moment(secondDate)
    var chargingTimeInMinutes = secondMomentDate.diff(firstMomentDate, 'm')
    return (
      Math.floor(chargingTimeInMinutes / 60) +
      'h' +
      ' ' +
      (chargingTimeInMinutes % 60) +
      'm'
    )
  }
  const { t } = useTranslation()
  const duration = getTimeBetweenDates(
    row.original.details.startedAt,
    row.original.details.endedAt,
  )
  const amountExists =
    row.original.amount === 0 || row.original.amount === '0' ? false : true
  const pointsExists =
    row.original.pointsAmount === 0 ||
    row.original.pointsAmount === undefined ||
    row.original.pointsAmount === '0'
      ? false
      : true
  const canViewReverseTransaction = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_REVERSE_TRANSACTION,
  )
  const ChargingSessionDetailItems = [
    {
      key: 'Name',
      value: row.original.details.station.address.streetName,
      translationKey: 'TransactionDetails.Name',
    },
    {
      key: 'Start',
      value: formatDate(row.original.details.startedAt),
      translationKey: 'TransactionDetails.Start',
    },
    {
      key: 'End',
      value: formatDate(row.original.details.endedAt),
      translationKey: 'TransactionDetails.End',
    },
    {
      key: 'Duration',
      value: duration,
      translationKey: 'TransactionDetails.Duration',
    },
    {
      key: 'Consumption',
      value: row.original.details?.energyConsumption,
      translationKey: 'TransactionDetails.Consumption',
    },
    {
      key: 'Tariff',
      value: row.original.details.subscription
        ? (row.original.details.subscription.tariff * 1000).toFixed(2)
        : '-',
      translationKey: 'TransactionDetails.Tariff',
    },
    {
      key: 'PointsTariff',
      value: row.original.details.subscription
        ? (row.original.details.subscription.pointsTariff * 1000).toFixed(2)
        : '-',
      translationKey: 'TransactionDetails.PointsTariff',
    },
    {
      key: 'ChargerType',
      value: row.original.details.chargerType,
      translationKey: 'TransactionDetails.ChargerType',
    },
    {
      key: 'ChargerID',
      value: row.original.details.station.code,
      translationKey: 'TransactionDetails.ChargerID',
    },
  ]
  const TransactionDetailItems = [
    {
      key: 'TokenType',
      value: row.original.details.startingChargingToken
        ? row.original.details.startingChargingToken?.type
        : '-',
      translationKey: 'TransactionDetails.TokenType',
    },
    {
      key: 'TokenID',
      value: row.original.details.startingChargingToken
        ? row.original.details.startingChargingToken.uid
        : '-',
      translationKey: 'TransactionDetails.TokenID',
    },
    {
      key: 'TransactionType',
      value: row.original.type,
      translationKey: 'TransactionDetails.TransactionType',
    },
    {
      key: 'Amount',
      value: amountExists
        ? row.original.isCredit
          ? `+${row.original.country?.currency?.symbol} ${Number(
              row.original.amount,
            ).toFixed(2)}`
          : `-${row.original.country?.currency?.symbol} ${Number(
              row.original.amount,
            ).toFixed(2)}`
        : '-',
      translationKey: 'TransactionDetails.Amount',
    },
    {
      key: 'PointsAmount',
      value: pointsExists
        ? row.original.isCredit
          ? `+PTS ${Intl.NumberFormat('en-US').format(
              row.original.pointsAmount,
            )}`
          : `-PTS ${Intl.NumberFormat('en-US').format(
              row.original.pointsAmount,
            )}`
        : '-',
      translationKey: 'TransactionDetails.PointsAmount',
    },
  ]

  return (
    <div
      className={styles.TransactionDetails}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.Line}>
        <div
          style={{ borderLeft: `1px solid ${Colors.DividerColor}` }}
          className={styles.Divider}
        />
        <div
          style={{ background: Colors.DividerColor }}
          className={styles.Circle}
        />
      </div>
      <div className={styles.Details}>
        <div className={styles.ChargingSessionDetails}>
          <IFText className={styles.TransactionTitle}>
            {t('TransactionDetails.CharginSessionTitle')}
          </IFText>
          <div className={styles.AllDetails}>
            {ChargingSessionDetailItems.map((item) => (
              <div className={styles.Detail} key={item.key}>
                <div className={styles.LabelTitle}>
                  <IFText
                    className={styles.DetailText}
                    style={{ color: Colors.DetailPanel }}
                  >
                    {t(item.translationKey)}
                  </IFText>
                </div>
                <div className={styles.LabelValue}>
                  <IFText className={styles.DetailText}>{item.value}</IFText>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.ChargingSessionDetails}>
          <IFText className={styles.TransactionTitle}>
            {t('TransactionDetails.TransactionDetailsTitle')}
          </IFText>
          <div className={styles.AllDetails}>
            {TransactionDetailItems.map((item) => (
              <div className={styles.Detail} key={item.key}>
                <div className={styles.LabelTitle}>
                  <IFText
                    className={styles.DetailText}
                    style={{ color: Colors.DetailPanel }}
                  >
                    {t(item.translationKey)}
                  </IFText>
                </div>
                <div className={styles.LabelValue}>
                  <IFText className={styles.DetailText}>{item.value}</IFText>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {canViewReverseTransaction && (
        <div className={styles.ReverseButtonContainer}>
          <IFButton
            color={Colors.primary}
            size="sm"
            text={
              row.original.status === InfinityEnums.TransactionStatus.NORMAL ||
              row.original.status === InfinityEnums.TransactionStatus.CORRECTED
                ? t('ReverseTransactionModal.ReverseButtonText')
                : t('ReverseTransactionModal.ReversedButtonText')
            }
            isLoading={false}
            isDead={
              row.original.status === InfinityEnums.TransactionStatus.VOID ||
              row.original.status === InfinityEnums.TransactionStatus.REVERSED
            }
            isFill={false}
            onClick={(e) => {
              e.stopPropagation()
              onReverseClick(row.original.id)
            }}
          />
        </div>
      )}
    </div>
  )
}
TransactionSessionDetail.propTypes = {
  row: PropTypes.object,
  onReverseClick: PropTypes.func,
  editingAdminRole: PropTypes.array,
}

export default TransactionSessionDetail
