import { React, useMemo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TransactionSelectors from 'Stores/Transaction/Selectors'
import TransactionActions from 'Stores/Transaction/Actions'
import { IFText, IFTable, IFButton } from 'Components'
import Styles from './TransactionsPageList.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Colors from 'Theme/Colors'
import { formatDate } from '../../Utils/StringFunctions'
import { Skeleton } from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'

const TransactionsPageList = ({
  transactions,
  transactionsFilter,
  paginationOffset,
  fetchTransactionsListRequestState,
  fetchTransactionsList,
  tablesHeight,
}) => {
  const { t } = useTranslation()
  const loadMoreData = () => {
    if (
      fetchTransactionsListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchTransactionsList(transactionsFilter, paginationOffset, failedRequest)
  }
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchTransactionsListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchTransactionsListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchTransactionsListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchTransactionsListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchTransactionsListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchTransactionsListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchTransactionsListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchTransactionsListRequestState])
  const handleRetry = () => {
    fetchTransactionsList(transactionsFilter, paginationOffset)
  }
  const transactionTableColumns = useMemo(
    () => [
      {
        accessorKey: 'transactionId',
        header:
          fetchTransactionsListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('TransactionsPage.TransactionTableColumns.TransactionId')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText> {row.original._id}</IFText>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'transactionType',
        size: 120,
        header:
          fetchTransactionsListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('TransactionsPage.TransactionTableColumns.TransactionType')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText> {row.original.type}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },

      {
        accessorKey: 'user',
        header:
          fetchTransactionsListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('TransactionsPage.TransactionTableColumns.UserId')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText>{row.original.user}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },

      {
        accessorKey: 'transactionAmount',
        size: 120,
        header:
          fetchTransactionsListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('TransactionsPage.TransactionTableColumns.Amount')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText>
                {row.original.isCredit
                  ? `+ ${Number(row.original.amount).toFixed(2)}`
                  : `- ${Number(row.original.amount).toFixed(2)}`}
              </IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'transactionEnergyConsumption',
        size: 120,
        header:
          fetchTransactionsListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('TransactionsPage.TransactionTableColumns.EnergyConsumption')
          ),
        Cell: ({ row }) => {
          const energyConsumption = row.original.details?.energyConsumption

          return row.index < transactions.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText>
                {energyConsumption !== null && energyConsumption !== undefined
                  ? Number(energyConsumption).toFixed(2)
                  : '-'}
              </IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'transactionDate',
        header:
          fetchTransactionsListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('TransactionsPage.TransactionTableColumns.TransactionDate')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText> {formatDate(row.original.createdAt)}</IFText>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <IFButton
                size="sm"
                color={Colors.red}
                text={t('IFTable.TryAgain')}
                onClick={() => {
                  handleRetry()
                }}
              />
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],

    [
      transactions,
      fetchTransactionsListRequestState,
      failedRequest,
      transactionsFilter,
    ],
  )
  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )

  return (
    <div className={Styles.tables}>
      {((fetchTransactionsListRequestState !== RequestState.SUCCEEDED &&
        !paginationOffset) ||
        transactions) && (
        <IFTable
          columns={transactionTableColumns}
          data={transactions}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
          paginationOffset={paginationOffset}
          filters={transactionsFilter}
          showSkeleton={
            (fetchTransactionsListRequestState === RequestState.LOADING &&
              paginationOffset === 0) ||
            (fetchTransactionsListRequestState !== RequestState.SUCCEEDED &&
              !paginationOffset)
          }
          tableMaxHeight={tablesHeight}
          tableSetHeight={'100%'}
          tableRequestState={fetchTransactionsListRequestState}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTransactionsList: (filter, offset, shouldShowError) =>
      dispatch(
        TransactionActions.fetchTransactionsList(
          filter,
          offset,
          shouldShowError,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  transactions: TransactionSelectors.getTransactionsList(state),
  paginationOffset: TransactionSelectors.getPaginationOffset(state),
  fetchTransactionsListRequestState:
    TransactionSelectors.getFetchTransactionsListRequestState(state),
  transactionsFilter: TransactionSelectors.getTransactionsFilter(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsPageList)
