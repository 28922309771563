import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { AuthTypes } from './Actions'
import RequestState from 'Enums/RequestState'
import IFFilterType from 'Enums/IFFilterType'

export const loginLoading = (state) => {
  return {
    ...state,
    loginLoading: true,
  }
}

export const loginResetLoading = (state) => {
  return {
    ...state,
    loginLoading: false,
  }
}

export const loginSuccess = (state, { token, refreshToken, user }) => {
  return {
    ...state,
    refreshToken: refreshToken,
    token: token,
    user: user,
  }
}

export const loginError = (state) => {
  return {
    ...state,
    token: null,
    refreshToken: null,
    user: null,
    loginLoading: false,
  }
}

export const getUserSuccess = (state, { user }) => {
  return {
    ...state,
    user: user,
  }
}

export const getUserFail = (state) => {
  return state
}

export const setAuthToken = (state, { token }) => {
  return {
    ...state,
    token: token,
  }
}

export const fetchEditingAdminRoleLoading = (state) => {
  return {
    ...state,
    fetchEditingAdminRoleRequestState: RequestState.LOADING,
  }
}

export const fetchEditingAdminRoleSuccess = (state, { role }) => {
  return {
    ...state,
    fetchEditingAdminRoleRequestState: RequestState.SUCCEEDED,
    editingAdminRole: role,
  }
}

export const fetchEditingAdminRoleError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchEditingAdminRoleRequestState: requestState,
  }
}
export const changePasswordSuccess = (state) => {
  return {
    ...state,
    ChangingPasswordRequestState: RequestState.SUCCEEDED,
  }
}

export const changePasswordLoading = (state) => {
  return {
    ...state,
    ChangingPasswordRequestState: RequestState.LOADING,
  }
}
export const setChangingPasswordRequestState = (state, { newRequestState }) => {
  return {
    ...state,
    ChangingPasswordRequestState: newRequestState,
  }
}

export const changePasswordFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    ChangingPasswordRequestState: requestState,
  }
}
export const fetchAdminDetailsLoading = (state) => {
  return {
    ...state,
    fetchAdminDetailsRequestState: RequestState.LOADING,
  }
}

export const fetchAdminDetailsSuccess = (state, { admin }) => {
  return {
    ...state,
    fetchAdminDetailsRequestState: RequestState.SUCCEEDED,
    user: admin,
  }
}

export const fetchAdminDetailsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminDetailsRequestState: requestState,
  }
}
export const setCurrentUserNewNameLoading = (state) => {
  return {
    ...state,
    ChangingUserNameRequestState: RequestState.LOADING,
  }
}
export const setChangingUserNameRequestState = (state, { newRequestState }) => {
  return {
    ...state,
    ChangingUserNameRequestState: newRequestState,
  }
}

export const setCurrentUserNewNameFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    ChangingUserNameRequestState: requestState,
  }
}

export const setAdminActionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    adminActionsFilters: newFilter,
  }
}
export const clearAdminActionsFilter = (state) => {
  return {
    ...state,
    adminActionsFilters: [
      {
        type: IFFilterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFilterType.DATE_RANGE,
        title: 'Date',
        initialValue: [],
        value: [],
      },
      {
        type: IFFilterType.CHECK,
        title: 'Action',
        data: ['Create', 'Edit', 'Delete', 'Activate', 'Deactivate'],
        initialValue: [],
        value: [],
      },
    ],
  }
}

export const fetchAdminActionsLoading = (state) => {
  return {
    ...state,
    fetchAdminActionsRequestState: RequestState.LOADING,
  }
}

export const fetchAdminActionsSuccess = (
  state,
  { offset, adminActions, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchAdminActionsRequestState: RequestState.SUCCEEDED,
      adminActionPaginationOffset: nextOffset,
      adminActions: [...adminActions],
    }
  } else {
    return {
      ...state,
      fetchAdminActionsRequestState: RequestState.SUCCEEDED,
      adminActionPaginationOffset: nextOffset,
      adminActions: [...state.adminActions, ...adminActions],
    }
  }
}
export const fetchAdminActionsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminActionsRequestState: requestState,
  }
}
export const clearAdminActions = (state) => {
  return {
    ...state,
    adminActions: [],
    adminActionPaginationOffset: 0,
    fetchAdminActionsRequestState: RequestState.UNINITIALIZED,
  }
}

export const setCurrentUserNewNameSuccess = (state, { userName }) => {
  let updatedUser = state.user
  updatedUser.name = userName
  return {
    ...state,
    user: updatedUser,
    ChangingUserNameRequestState: RequestState.SUCCEEDED,
  }
}

export const resetPasswordLoading = (state) => {
  return {
    ...state,
    resetPasswordRequestState: RequestState.LOADING,
  }
}

export const resetPasswordSuccess = (state) => {
  return {
    ...state,
    resetPasswordRequestState: RequestState.SUCCEEDED,
  }
}

export const resetPasswordFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }

  return {
    ...state,
    resetPasswordRequestState: requestState,
  }
}

export const resetPasswordVerificationLoading = (state) => {
  return {
    ...state,
    resetPasswordVerificationRequestState: RequestState.LOADING,
  }
}

export const resetPasswordVerificationSuccess = (state, { emailID }) => {
  return {
    ...state,
    resetPasswordVerificationRequestState: RequestState.SUCCEEDED,
    resetPasswordEmailID: emailID,
  }
}

export const resetPasswordVerificationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }

  return {
    ...state,
    resetPasswordVerificationRequestState: requestState,
  }
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.LOGIN_LOADING]: loginLoading,
  [AuthTypes.LOGIN_RESET_LOADING]: loginResetLoading,
  [AuthTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthTypes.LOGIN_ERROR]: loginError,

  [AuthTypes.SET_AUTH_TOKEN]: setAuthToken,

  [AuthTypes.GET_USER_SUCCESS]: getUserSuccess,
  [AuthTypes.GET_USER_FAIL]: getUserFail,
  [AuthTypes.FETCH_EDITING_ADMIN_ROLE_LOADING]: fetchEditingAdminRoleLoading,
  [AuthTypes.FETCH_EDITING_ADMIN_ROLE_SUCCESS]: fetchEditingAdminRoleSuccess,
  [AuthTypes.FETCH_EDITING_ADMIN_ROLE_ERROR]: fetchEditingAdminRoleError,

  [AuthTypes.CHANGE_PASSWORD_LOADING]: changePasswordLoading,
  [AuthTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [AuthTypes.CHANGE_PASSWORD_FAIL]: changePasswordFail,
  [AuthTypes.SET_CHANGING_PASSWORD_REQUEST_STATE]:
    setChangingPasswordRequestState,

  [AuthTypes.FETCH_ADMIN_DETAILS_LOADING]: fetchAdminDetailsLoading,
  [AuthTypes.FETCH_ADMIN_DETAILS_SUCCESS]: fetchAdminDetailsSuccess,
  [AuthTypes.FETCH_ADMIN_DETAILS_FAIL]: fetchAdminDetailsFail,

  [AuthTypes.SET_CURRENT_USER_NEW_NAME_LOADING]: setCurrentUserNewNameLoading,
  [AuthTypes.SET_CURRENT_USER_NEW_NAME_SUCCESS]: setCurrentUserNewNameSuccess,
  [AuthTypes.SET_CURRENT_USER_NEW_NAME_FAIL]: setCurrentUserNewNameFail,
  [AuthTypes.SET_CHANGING_USER_NAME_REQUEST_STATE]:
    setChangingUserNameRequestState,

  [AuthTypes.FETCH_ADMIN_ACTIONS_LOADING]: fetchAdminActionsLoading,
  [AuthTypes.FETCH_ADMIN_ACTIONS_SUCCESS]: fetchAdminActionsSuccess,
  [AuthTypes.FETCH_ADMIN_ACTIONS_ERROR]: fetchAdminActionsError,

  [AuthTypes.SET_ADMIN_ACTIONS_FILTER]: setAdminActionsFilter,
  [AuthTypes.CLEAR_ADMIN_ACTIONS_FILTER]: clearAdminActionsFilter,

  [AuthTypes.CLEAR_ADMIN_ACTIONS]: clearAdminActions,

  [AuthTypes.RESET_PASSWORD_VERIFICATION_LOADING]:
    resetPasswordVerificationLoading,
  [AuthTypes.RESET_PASSWORD_VERIFICATION_SUCCESS]:
    resetPasswordVerificationSuccess,
  [AuthTypes.RESET_PASSWORD_VERIFICATION_FAIL]: resetPasswordVerificationFail,

  [AuthTypes.RESET_PASSWORD_LOADING]: resetPasswordLoading,
  [AuthTypes.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [AuthTypes.RESET_PASSWORD_FAIL]: resetPasswordFail,
})
