import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  setFilterText: ['text'],
  updateAdminsFilterWithRoles: ['roles'],
  clearAdmins: null,
  fetchAdminList: ['offset', 'shouldShowError'],
  fetchAdminListLoading: null,
  fetchAdminListSuccess: [
    'offset',
    'admins',
    'nextOffset',
    'filterObject',
    'roles',
  ],
  fetchAdminListError: ['error'],

  updateAdminRole: ['adminId', 'newRoleValue'],
  updateAdminRoleLoading: ['adminId'],
  updateAdminRoleSuccess: ['admin'],
  updateAdminRoleFail: ['adminId'],

  deleteAdmin: ['adminId'],
  deleteAdminLoading: null,
  deleteAdminSuccess: ['adminId'],
  deleteAdminFail: ['error'],

  fetchAdminsCount: null,
  fetchAdminsCountLoading: null,
  fetchAdminsCountSuccess: ['count', 'filterObject'],
  fetchAdminsCountFail: ['error'],

  inviteAdmin: ['inviteAdminData'],
  inviteAdminLoading: null,
  inviteAdminSuccess: ['inviteAdminData'],
  inviteAdminFail: ['error'],

  fetchInviteAdminList: ['offset', 'shouldShowError'],
  fetchInviteAdminListLoading: null,
  fetchInviteAdminListSuccess: ['inviteAdmins', 'offset'],
  fetchInviteAdminListFail: ['error'],

  deleteInviteAdmin: ['inviteAdminId'],
  deleteInviteAdminLoading: null,
  deleteInviteAdminSuccess: ['inviteAdminId'],
  deleteInviteAdminFail: ['error'],

  sendAdminEmail: ['email'],
  sendAdminEmailLoading: null,
  sendAdminEmailSuccess: null,
  sendAdminEmailFail: ['error'],

  fetchInviteAdmin: ['inviteAdminId'],
  fetchInviteAdminLoading: null,
  fetchInviteAdminSuccess: ['inviteAdmin'],
  fetchInviteAdminFail: ['error'],
  setInviteAdminStatus: ['status'],

  createAdmin: ['admin'],
  createAdminLoading: null,
  createAdminSuccess: null,
  createAdminFail: ['error'],

  fetchAdminsInvitationsCount: null,
  fetchAdminsInvitationsCountLoading: null,
  fetchAdminsInvitationsCountSuccess: ['adminsInvitationscount'],
  fetchAdminsInvitationsCountFail: ['error'],
})

export const AdminTypes = Types
export default Creators
