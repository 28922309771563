import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserActions from 'Stores/User/Actions'
import { Colors } from 'Theme'
import {
  IFButton,
  IFDialog,
  IFModal,
  IFText,
  IFTable,
  IFSkeleton,
} from 'Components'
import Styles from './ChargingTokenList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'
import AddCardForm from 'Containers/UserPage/AddCardForm/AddCardForm'
import RequestState from 'Enums/RequestState'
import { formatDate } from '../../Utils/StringFunctions'

const ChargingTokenList = ({
  userId,
  currentUserChargingTokens,
  setCurrentUserChargingTokenActive,
  editingAdminRole,
  userDetailsRequestState,
  addCard,
  fetchUserListRequestState,
  addCurrentCardRequestState,
  selectedUserIndex,
}) => {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const showDialogHandlerAddCard = useRef(null)
  const [selectedRow, setSelectedRow] = useState({})
  const canEditToken = editingAdminRole.includes(
    InfinityEnums.AdminPermissions.CAN_TOGGLE_CARD_ACTIVATION,
  )
  const canEditUser = editingAdminRole.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_USERS,
  )

  const showDialogHandler = useRef()
  useEffect(() => {
    if (addCurrentCardRequestState === RequestState.SUCCEEDED) {
      showDialogHandlerAddCard?.current?.dismiss()
      setSelectedRow({})
    }
  }, [addCurrentCardRequestState])
  const chargingTokenTableColumns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ||
          selectedUserIndex === -1 ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('ChargingTokenList.type')
          ),
        Cell: ({ row }) => <IFText>{row.original.type}</IFText>,
      },
      {
        accessorKey: 'id',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ||
          selectedUserIndex === -1 ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('ChargingTokenList.id')
          ),
        Cell: ({ row }) => (
          <IFText> {canEditUser ? row.original._id : '-'}</IFText>
        ),
      },
      {
        accessorKey: 'visualNumber',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ||
          selectedUserIndex === -1 ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('ChargingTokenList.visualNumber')
          ),
        Cell: ({ row }) => (
          <IFText>{`${
            row.original.visualNumber ? row.original.visualNumber : '-'
          }`}</IFText>
        ),
      },
      {
        accessorKey: 'updatedAt',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ||
          selectedUserIndex === -1 ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('ChargingTokenList.updatedAt')
          ),
        Cell: ({ row }) => (
          <IFText>{formatDate(row.original.updatedAt)}</IFText>
        ),
      },
      ...(canEditToken && canEditUser
        ? [
            {
              accessorKey: 'deactivateButton',
              header:
                userDetailsRequestState === RequestState.UNINITIALIZED ||
                userDetailsRequestState === RequestState.LOADING ? (
                  <IFSkeleton variant="text" width="110px" height={20} />
                ) : (
                  t('ChargingTokenList.deactivateButton')
                ),
              Cell: ({ row }) => (
                <div>
                  {canEditToken ? (
                    <div className={Styles.deactivateWrapper}>
                      <div className={Styles.deactivateContainer}>
                        {!canEditUser &&
                        row.original.status ===
                          InfinityEnums.AppChargingTokenStatus.ACTIVE ? null : (
                          <IFButton
                            size="sm"
                            text={
                              !canEditUser
                                ? row.original.status ===
                                  InfinityEnums.AppChargingTokenStatus
                                    .DEACTIVATED
                                  ? t('UserPage.Deactivated')
                                  : t('ChargingTokenList.Active')
                                : row.original.status ===
                                  InfinityEnums.AppChargingTokenStatus
                                    .DEACTIVATED
                                ? t('UserPage.Deactivated')
                                : t('UserPage.DeactivateButton')
                            }
                            isDead={
                              row.original.status ===
                                InfinityEnums.AppChargingTokenStatus
                                  .DEACTIVATED || !canEditUser
                            }
                            isLoading={row.original.isLoading}
                            color={Colors.UserPageDeactivateButton}
                            onClick={() => {
                              setSelectedRow(row)
                              showDialogHandler.current.show()
                            }}
                          />
                        )}
                      </div>
                      <IFDialog
                        ref={showDialogHandler}
                        open={false}
                        maxWidth={'xs'}
                        fullWidth={true}
                        title={t('UserPage.DeactivateTitleToken')}
                        bodyText={t('UserPage.DeactivateTokenText')}
                        buttonAcceptonClick={() => {
                          setCurrentUserChargingTokenActive(
                            userId,
                            selectedRow.original._id,
                            selectedRow.index,
                          )
                        }}
                        buttonCancelColor={Colors.UserPageCancel}
                        buttonAcceptColor={Colors.UserPageDeactivateButton}
                        buttonAcceptText={t('UserPage.DeactivateConfirm')}
                      />
                    </div>
                  ) : (
                    <IFText>-</IFText>
                  )}
                </div>
              ),
            },
          ]
        : []),
    ],
    [
      currentUserChargingTokens,
      fetchUserListRequestState,
      userDetailsRequestState,
      selectedRow,
    ],
  )
  const renderToolbar = ({ table }) => {
    const isFullScreen = table.getState().isFullScreen
    return (
      <>
        {isFullScreen ? (
          <div className={Styles.TopToolBarFullScreen}>
            <IFText
              className={Styles.HeaderText}
              style={{ color: Colors.UserPageTextColor }}
              skeletonWidth="180px"
              loadSkeleton={
                fetchUserListRequestState === RequestState.LOADING ||
                userDetailsRequestState === RequestState.LOADING ||
                selectedUserIndex === -1
              }
            >
              {t('UserPage.ChargingTokensTitle')}
            </IFText>
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_ADD_CHARGING_TOKEN,
            ) &&
              (fetchUserListRequestState === RequestState.LOADING ||
              userDetailsRequestState === RequestState.LOADING ||
              selectedUserIndex === -1 ? (
                <></>
              ) : (
                <div className={Styles.IconHover}>
                  <IFButton
                    size="sm"
                    color={Colors.primary}
                    text={`+ ${t('UserPage.ChargingTokensButton')}`}
                    onClick={() => {
                      setError('')
                      showDialogHandlerAddCard.current.show()
                    }}
                  />
                </div>
              ))}
          </div>
        ) : (
          <div className={Styles.TopToolBar}>
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_ADD_CHARGING_TOKEN,
            ) &&
              (fetchUserListRequestState === RequestState.LOADING ||
              userDetailsRequestState === RequestState.LOADING ||
              selectedUserIndex === -1 ? (
                <></>
              ) : (
                <div className={Styles.IconHover}>
                  <IFButton
                    size="sm"
                    color={Colors.primary}
                    text={`+ ${t('UserPage.ChargingTokensButton')}`}
                    onClick={() => {
                      setError('')
                      showDialogHandlerAddCard.current.show()
                    }}
                  />
                </div>
              ))}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <IFTable
        columns={chargingTokenTableColumns}
        data={currentUserChargingTokens}
        showSkeleton={
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ||
          selectedUserIndex === -1
            ? true
            : false
        }
        tableRequestState={userDetailsRequestState}
        tableMaxHeight={226}
        enableFullScreen={true}
        enableToolBar={true}
        renderToolbar={renderToolbar}
      />
      <IFModal
        title={t('UserPage.AddCardTitle')}
        open={false}
        fullWidth={true}
        ref={showDialogHandlerAddCard}
        className={Styles.AddCardForm}
      >
        <AddCardForm
          error={error}
          onSubmit={(value) => {
            const cleanedValue = value.replace(/(\r\n|\n|\r)/gm, ' ')

            const words = cleanedValue.split(' ')

            const formattedValue = words
              .map((word, index) => {
                if (index === words.length - 1) {
                  return word
                }
                return word.includes(',') ? word : `${word},`
              })
              .join('')

            addCard(userId, formattedValue, (e) => {
              setError(e)
            })
          }}
        />
      </IFModal>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCurrentUserPaymentCard: (userId, paymentCardId, index) => {
      dispatch(
        UserActions.deleteCurrentUserPaymentCard(userId, paymentCardId, index),
      )
    },
    addCard: (id, serialNumbers, onError) =>
      dispatch(UserActions.addCurrentUserNewCard(id, serialNumbers, onError)),
    setCurrentUserChargingTokenActive: (userId, chargingTokenId, index) =>
      dispatch(
        UserActions.setCurrentUserChargingTokenActive(
          userId,
          chargingTokenId,
          index,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  currentUserChargingTokens: UserSelectors.getCurrentUserChargingTokens(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  userDetailsRequestState: UserSelectors.getUserDetailsRequestState(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  addCurrentCardRequestState:
    UserSelectors.getAddCurrentCardRequestState(state),
  admin: AuthSelectors.getUser(state),
  selectedUserIndex: UserSelectors.getSelectedUserIndex(state),
})

ChargingTokenList.propTypes = {
  currentUserChargingTokens: PropTypes.arrayOf(PropTypes.object),
  setCurrentUserChargingTokenActive: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargingTokenList)
