import { INITIAL_STATE } from './initialState'
import { VoucherTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFilterType from 'Enums/IFFilterType'

export const fetchVouchersListLoading = (state) => {
  return {
    ...state,
    fetchVouchersListRequestState: RequestState.LOADING,
  }
}

export const fetchVouchersListSuccess = (
  state,
  { offset, vouchers, nextOffset },
) => {
  let result = []
  for (let j = 0; j < vouchers.length; j++) {
    if (!state.vouchers.some((voucher) => voucher.id === vouchers[j].id)) {
      if (!state.vouchers.some((voucher) => voucher.id === vouchers[j].id)) {
        result.push(vouchers[j])
      }
    }
  }
  if (offset === 0)
    return {
      ...state,
      vouchers: [...vouchers],
      paginationOffset: nextOffset,
      fetchVouchersListRequestState: RequestState.SUCCEEDED,
    }
  else {
    return {
      ...state,
      vouchers: [...state.vouchers, ...result],
      paginationOffset: nextOffset,
      fetchVouchersListRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchVouchersListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchVouchersListRequestState: requestState,
  }
}

export const setVouchersFilter = (state, { newFilter }) => {
  return {
    ...state,
    vouchersFilter: newFilter,
  }
}

export const clearVouchers = (state) => {
  return {
    ...state,
    vouchers: [],
    paginationOffset: 0,
    vouchersCount: 0,
    vouchersFilter: [
      {
        type: IFFilterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFilterType.DATE,
        title: 'Expires before',
        initialValue: '',
        value: '',
      },
      {
        type: IFFilterType.CHECK,
        title: 'Validity',
        data: ['Valid', 'Expired'],
        initialValue: [],
        value: [],
      },
    ],
    fetchVouchersListRequestState: RequestState.UNINITIALIZED,
  }
}

export const updateVoucherLoading = (state) => {
  return {
    ...state,
    updateVoucherRequestState: RequestState.LOADING,
  }
}

export const updateVoucherSuccess = (state, { index, newVoucher }) => {
  let newVouchers = state.vouchers
  newVouchers[index] = newVoucher
  return {
    ...state,
    vouchers: [...newVouchers],
    updateVoucherRequestState: RequestState.SUCCEEDED,
  }
}

export const addVoucherSuccess = (state, { voucher }) => {
  return {
    ...state,
    vouchers: [voucher, ...state.vouchers],
    vouchersCount: state.vouchersCount + 1,
    isAddVoucherLoading: false,
  }
}

export const addVoucherLoading = (state) => {
  return {
    ...state,
    isAddVoucherLoading: true,
  }
}

export const addVoucherFail = (state) => {
  return {
    ...state,
    isAddVoucherLoading: false,
  }
}

export const updateVoucherFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateVoucherRequestState: requestState,
  }
}
export const fetchVouchersCountSuccess = (state, { vouchersCount }) => {
  return {
    ...state,
    vouchersCount: vouchersCount,
    fetchVouchersCountRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchVouchersCountLoading = (state) => {
  return {
    ...state,
    fetchVouchersCountRequestState: RequestState.LOADING,
  }
}
export const fetchVouchersCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchVouchersCountRequestState: requestState,
  }
}
export const setUpdateVoucherRequestState = (state, { newState }) => {
  return {
    ...state,
    updateVoucherRequestState: newState,
  }
}
export const clearVouchersFilter = (state) => {
  return {
    ...state,
    vouchersFilter: [
      {
        type: IFFilterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFilterType.DATE,
        title: 'Expires before',
        initialValue: '',
        value: '',
      },
      {
        type: IFFilterType.CHECK,
        title: 'Validity',
        data: ['Valid', 'Expired'],
        initialValue: [],
        value: [],
      },
    ],
  }
}

export const deleteVoucherLoading = (state) => {
  return {
    ...state,
    deleteVoucherRequestState: RequestState.LOADING,
  }
}

export const deleteVoucherSuccess = (state, { voucherId }) => {
  const updatedVouchersList = state.vouchers.filter(
    (voucher) => voucher.id !== voucherId,
  )
  return {
    ...state,
    vouchers: updatedVouchersList,
    deleteVoucherRequestState: RequestState.SUCCEEDED,
    vouchersCount: state.vouchersCount - 1 < 0 ? 0 : state.vouchersCount - 1,
  }
}
export const deleteVoucherFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteVoucherRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [VoucherTypes.FETCH_VOUCHERS_LIST_LOADING]: fetchVouchersListLoading,
  [VoucherTypes.FETCH_VOUCHERS_LIST_SUCCESS]: fetchVouchersListSuccess,
  [VoucherTypes.FETCH_VOUCHERS_LIST_ERROR]: fetchVouchersListError,
  [VoucherTypes.SET_VOUCHERS_FILTER]: setVouchersFilter,
  [VoucherTypes.CLEAR_VOUCHERS]: clearVouchers,
  [VoucherTypes.ADD_VOUCHER_SUCCESS]: addVoucherSuccess,
  [VoucherTypes.ADD_VOUCHER_LOADING]: addVoucherLoading,
  [VoucherTypes.ADD_VOUCHER_FAIL]: addVoucherFail,
  [VoucherTypes.UPDATE_VOUCHER_FAIL]: updateVoucherFail,
  [VoucherTypes.UPDATE_VOUCHER_SUCCESS]: updateVoucherSuccess,
  [VoucherTypes.UPDATE_VOUCHER_LOADING]: updateVoucherLoading,
  [VoucherTypes.FETCH_VOUCHERS_COUNT_SUCCESS]: fetchVouchersCountSuccess,
  [VoucherTypes.FETCH_VOUCHERS_COUNT_LOADING]: fetchVouchersCountLoading,
  [VoucherTypes.FETCH_VOUCHERS_COUNT_FAIL]: fetchVouchersCountFail,
  [VoucherTypes.SET_UPDATE_VOUCHER_REQUEST_STATE]: setUpdateVoucherRequestState,
  [VoucherTypes.DELETE_VOUCHER_FAIL]: deleteVoucherFail,
  [VoucherTypes.DELETE_VOUCHER_SUCCESS]: deleteVoucherSuccess,
  [VoucherTypes.DELETE_VOUCHER_LOADING]: deleteVoucherLoading,
})
