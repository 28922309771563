import React, { useRef, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import Styles from './AdminActionList.module.css'
import AdminActionSkeleton from './AdminActionSkeleton'
import { IFButton, IFText } from 'Components'
import Timeline from '@mui/lab/Timeline'
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined'
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined'
import { Virtuoso } from 'react-virtuoso'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'
import { Waypoint } from 'react-waypoint'
import { Popover } from '@mui/material'
import ReactCountryFlag from 'react-country-flag'
import RequestState from 'Enums/RequestState'
import { Colors } from 'Theme'
import InfinityEnums from 'Enums/InfinityEnums'
import ReportIcon from '@mui/icons-material/Report'
import { useTranslation } from 'react-i18next'

const AdminActionList = ({
  fetchAdminActionsRequestState,
  paginationOffset,
  flattenedActions,
  tablesHeight,
  loadMoreData,
  handleRetry,
}) => {
  const { t } = useTranslation()
  const options = {
    scrollbars: {
      visibility: 'auto',
      autoHide: 'move',
      autoHideDelay: 1000,
    },
  }
  const rootRef = useRef(null)
  const virtuosoRef = useRef(null)
  const [failedRequest, setFailedRequest] = useState(false)
  const [scroller, setScroller] = useState(null)
  const [initialize, osInstance] = useOverlayScrollbars({
    options,
    defer: true,
  })

  useEffect(() => {
    const { current: root } = rootRef
    if (scroller && root) {
      initialize({
        target: root,
        elements: {
          viewport: scroller,
        },
      })
    }
    return () => osInstance()?.destroy()
  }, [scroller, initialize, osInstance])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null)
  const handlePopoverOpen = (event, index) => {
    setOpenPopoverIndex(index)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setOpenPopoverIndex(null)
  }
  const Footer = () => {
    return paginationOffset &&
      flattenedActions &&
      flattenedActions.length !== 0 ? (
      <div>
        {failedRequest ? (
          <div className={Styles.IconDiv}>
            <Waypoint onEnter={loadMoreData} />
            <div className={Styles.ReportIconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
              <div className={Styles.TextDiv}>
                <IFButton
                  size="sm"
                  color={Colors.red}
                  text={t('IFTable.TryAgain')}
                  onClick={() => {
                    handleRetry()
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={Styles.LoadingMore}>
            <Box className={Styles.SkeletonLineDiv}>
              <Box
                className={Styles.SkeletonLine}
                sx={{
                  backgroundColor: Colors.ActionLine,
                }}
              />
            </Box>
            <AdminActionSkeleton />
            <Box className={Styles.SkeletonLineDiv}>
              <Box
                className={Styles.SkeletonLine}
                sx={{
                  backgroundColor: Colors.ActionLine,
                }}
              />
            </Box>
            <AdminActionSkeleton />
          </div>
        )}
      </div>
    ) : null
  }
  useEffect(() => {
    if (
      fetchAdminActionsRequestState === RequestState.ERROR_0_NETWORK ||
      fetchAdminActionsRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchAdminActionsRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchAdminActionsRequestState])
  return (
    <div className={Styles.AdminActionList} style={{ height: tablesHeight }}>
      {fetchAdminActionsRequestState === RequestState.LOADING &&
      paginationOffset === 0 ? (
        <>
          {[...Array(12)].map((_, index) => (
            <>
              {index !== 0 && (
                <Box className={Styles.SkeletonLineDiv}>
                  <Box
                    className={Styles.SkeletonLine}
                    sx={{
                      backgroundColor: Colors.grey,
                    }}
                  />
                </Box>
              )}
              <AdminActionSkeleton />
            </>
          ))}
        </>
      ) : flattenedActions && flattenedActions.length === 0 ? (
        <div className={Styles.NoRecord}>
          <IFText>{t('UserPage.TransactionNoRecord')}</IFText>
        </div>
      ) : (
        <Timeline
          className={Styles.timeline}
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <div
            ref={rootRef}
            data-overlayscrollbars=""
            className={Styles.VirtuosoDiv}
          >
            <Virtuoso
              ref={virtuosoRef}
              style={{
                overflow: 'hidden',
              }}
              data={flattenedActions}
              endReached={() => {
                if (
                  paginationOffset &&
                  flattenedActions &&
                  flattenedActions.length > 0
                ) {
                  loadMoreData()
                }
              }}
              increaseViewportBy={480}
              itemContent={(index, action) => (
                <div key={`ActionItem ${index}`}>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        className={Styles.TimeLineDot}
                        style={{
                          backgroundColor: Colors.ActionDotBackground,
                        }}
                      >
                        {action.verb === InfinityEnums.AdminActions.Edit ? (
                          <EditOutlinedIcon
                            className={Styles.Icons}
                            style={{
                              color: Colors.black,
                            }}
                          />
                        ) : action.verb ===
                          InfinityEnums.AdminActions.Delete ? (
                          <DeleteOutlineIcon
                            className={Styles.Icons}
                            style={{
                              color: Colors.black,
                            }}
                          />
                        ) : action.verb ===
                          InfinityEnums.AdminActions.Create ? (
                          <>
                            <AddIcon
                              aria-owns={
                                openPopoverIndex === index
                                  ? 'mouse-over-popover'
                                  : undefined
                              }
                              aria-haspopup="true"
                              onMouseEnter={(event) =>
                                handlePopoverOpen(event, index)
                              }
                              onMouseLeave={handlePopoverClose}
                              className={Styles.Icons}
                              style={{
                                color: Colors.black,
                              }}
                            />
                            <Popover
                              id="mouse-over-popover"
                              sx={{ pointerEvents: 'none' }}
                              open={openPopoverIndex === index}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              <div className={Styles.Popover}>
                                {action.attributes.map(({ name, newValue }) => {
                                  if (newValue === '') return null
                                  if (
                                    typeof newValue === 'object' &&
                                    !Array.isArray(newValue)
                                  ) {
                                    return (
                                      <div
                                        className={Styles.DetailItem}
                                        key={name}
                                      >
                                        <div
                                          className={Styles.LabelTitleObject}
                                        >
                                          <IFText
                                            style={{
                                              color: Colors.DetailPanel,
                                            }}
                                          >
                                            {name}
                                          </IFText>
                                        </div>
                                        <div
                                          className={Styles.LabelValueObject}
                                        >
                                          {Object.entries(newValue).map(
                                            ([key, value]) => (
                                              <IFText>
                                                <strong>{key}</strong>: {value}
                                              </IFText>
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    )
                                  }
                                  return (
                                    <div
                                      key={name}
                                      className={Styles.DetailItem}
                                    >
                                      <div className={Styles.LabelTitle}>
                                        <IFText
                                          style={{
                                            color: Colors.DetailPanel,
                                          }}
                                        >
                                          {name}
                                        </IFText>
                                      </div>
                                      <div className={Styles.LabelValue}>
                                        <IFText>{newValue.toString()}</IFText>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </Popover>
                          </>
                        ) : action.verb ===
                          InfinityEnums.AdminActions.Activate ? (
                          <ToggleOnOutlinedIcon
                            className={Styles.Icons}
                            style={{
                              color: Colors.black,
                            }}
                          />
                        ) : action.verb ===
                          InfinityEnums.AdminActions.Deactivate ? (
                          <ToggleOffOutlinedIcon
                            className={Styles.Icons}
                            style={{
                              color: Colors.black,
                            }}
                          />
                        ) : null}
                      </TimelineDot>

                      {index !== flattenedActions.length - 1 && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent className={Styles.TimeLineContent}>
                      <div>
                        <IFText
                          className={Styles.CountryName}
                          style={{
                            color: Colors.text,
                          }}
                        >
                          {action.phrase}
                        </IFText>
                      </div>
                      <div className={Styles.DateCountryDiv}>
                        <IFText
                          className={Styles.DateName}
                          style={{
                            color: Colors.gray,
                          }}
                        >
                          {action.createdAt}
                        </IFText>
                        {action.country && (
                          <ReactCountryFlag
                            className={Styles.FlagItemAction}
                            countryCode={action.country}
                            svg
                          />
                        )}
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                </div>
              )}
              scrollerRef={setScroller}
              components={{ Footer: Footer }}
            />
          </div>
        </Timeline>
      )}
    </div>
  )
}

export default AdminActionList
