import axios from 'axios'
import { Config } from 'Config'
import i18n from 'i18next'
import { toaster } from 'rsuite'
import AuthActions from 'Stores/Auth/Actions'
import { IFToastMessage } from 'Components'

var base64 = require('base-64')

let store
const DEFAULT_TIMEOUT = 0
const DEFAULT_OPTIONS = {
  timeout: DEFAULT_TIMEOUT,
  headers: { store },
}

/**
 * Creates axios instances with different custom configurations
 */
export const adminActionsApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/adminActions',
})

export const adminsApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/admins',
})

export const rolesApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/roles',
})

export const usersApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/users',
})

export const transactionsApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/transactions',
})

export const pointsApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/points',
})

const tokenRefreshService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/admins/refreshAccessToken',
})

export const notificationGroupsApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/notificationGroups',
})

export const vouchersApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/vouchers',
})

export const paymentSessionApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/payment',
})
export const dashboardApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/dashboard',
})

// axiosRetry(chargingApiService, { retries: 10, retryDelay: 6000 })

export function initApiService(_store) {
  store = _store
}

export function handleError(error) {
  if (error.status && error.message && error.message.en) {
    if (i18n.language === 'ar') {
      toaster.push(<IFToastMessage type="error" text={error.message.ar} />)
    } else {
      toaster.push(<IFToastMessage type="error" text={error.message.en} />)
    }
  } else {
    if (i18n.language === 'ar') {
      toaster.push(
        <IFToastMessage type="error" text="لا يوجد اتصال بالإنترنت" />,
      )
    } else {
      toaster.push(
        <IFToastMessage type="error" text="No Internet Connection" />,
      )
    }
  }
}
export function handleSuccess(message) {
  if (i18n.language === 'en') {
    toaster.push(<IFToastMessage type="success" text={message} />)
  }
}
/**
 * Sets Authorization header
 */
export const setAuthHeader = (token, refreshToken) => {
  store.dispatch(AuthActions.setAuthToken(token))
  if (refreshToken)
    tokenRefreshService.defaults.headers.common['x-refresh-token'] =
      refreshToken
  _executeOnAllServices((service) => {
    service.defaults.headers.common['x-auth-token'] = token
    if (refreshToken)
      service.defaults.headers.common['x-refresh-token'] = refreshToken
  })
}
;(function () {
  _handleUnauthorizedRequests()
})()

function _handleUnauthorizedRequests() {
  const flattenErrorInfo = ({
    response: {
      status,
      statusText,
      data: { message },
      data: { data },
      data: { code },
      data: { details },
    },
  }) => {
    return {
      status,
      statusText,
      message,
      data,
      code,
      details,
    }
  }

  const globalErrorHandler = (error) => {
    if (error && error.response) {
      return Promise.reject(flattenErrorInfo(error))
    } else {
      return Promise.reject(error)
    }
  }

  _executeOnAllServices((service) => {
    service.interceptors.response.use(
      (response) => response,
      globalErrorHandler,
    )

    service.interceptors.request.use(async (config) => {
      config.headers.common['x-auth-token'] =
        store.getState().secure.authState.token
      config.headers.common['x-refresh-token'] =
        store.getState().secure.authState.refreshToken
      if (
        store.getState().secure.authState.token !== null &&
        config.baseURL !== Config.API_URL + '/auth'
      ) {
        const [, payload] = config.headers.common['x-auth-token'].split('.')
        const { exp: expires } = JSON.parse(base64.decode(payload))
        const now = Date.now()
        let expiry = expires * 1000
        const diff = (expiry - now) / 60000

        try {
          if (diff < 5) {
            console.log('need to refresh token first')
            const tokenResponse = await tokenRefreshService.post(
              '/',
              {},
              {
                headers: {
                  'x-auth-token': store.getState().secure.authState.token,
                  'x-refresh-token':
                    store.getState().secure.authState.refreshToken,
                },
              },
            )
            config.headers.common['x-auth-token'] =
              tokenResponse.headers['x-auth-token']
            setAuthHeader(tokenResponse.headers['x-auth-token'])
          }
        } catch (error) {
          if (error && error.response) {
            const { status } = error.response
            if (status === 401) {
              // TODO: Navigate to Login & revoke access & reset data
              console.log('Unauthorized')
              window.location = '/login'
              toaster.push(
                <IFToastMessage type="error" text="Please login again" />,
              )
            }
            return Promise.reject(flattenErrorInfo(error))
          } else {
            return Promise.reject(error)
          }
        }
      }
      return config
    })
  })
}

/**
 * Helper function
 */
function _executeOnAllServices(action) {
  ;[adminsApiService].forEach(action)
  ;[usersApiService].forEach(action)
  ;[transactionsApiService].forEach(action)
  ;[notificationGroupsApiService].forEach(action)
  ;[vouchersApiService].forEach(action)
  ;[pointsApiService].forEach(action)
  ;[rolesApiService].forEach(action)
  ;[adminActionsApiService].forEach(action)
  ;[dashboardApiService].forEach(action)
}
