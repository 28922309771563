import { put, call } from 'redux-saga/effects'
import { rolesApiService, handleError } from 'Services/ApiService.js'
import roleActions from 'Stores/Role/Actions'
import i18n from 'i18next'
import { IFToastMessage } from 'Components'
import { toaster } from 'rsuite'

export function* fetchRoles({}) {
  yield put(roleActions.fetchRolesLoading())

  try {
    const { data } = yield call(rolesApiService.get, '/')

    yield put(roleActions.fetchRolesSuccess(data))
  } catch (e) {
    yield put(roleActions.fetchRolesError(e))
    handleError(e)
  }
}
export function* fetchRolesWithAdmins({ offset, shouldShowError }) {
  if (offset === 0) {
    yield put(roleActions.fetchRolesWithAdminsLoading())
  }

  try {
    const { data } = yield call(rolesApiService.get, '/rolesWithAdmins', {
      params: {
        offset: offset,
      },
    })

    yield put(
      roleActions.fetchRolesWithAdminsSuccess(
        offset,
        data.rolesWithAdmins,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(roleActions.fetchRolesWithAdminsError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* fetchRolesCount() {
  yield put(roleActions.fetchRolesCountLoading())
  try {
    const { data } = yield call(rolesApiService.get, '/count')
    yield put(roleActions.fetchRolesCountSuccess(data.count))
  } catch (e) {
    yield put(roleActions.fetchRolesCountFail(e))
    handleError(e)
  }
}

export function* updateRole({ roleId, newRoleName, newRolePermissions }) {
  yield put(roleActions.updateRoleLoading(roleId))

  try {
    const { data } = yield call(rolesApiService.patch, `/${roleId}`, {
      name: newRoleName,
      permissions: newRolePermissions,
    })

    yield put(roleActions.updateRoleSuccess(data))
  } catch (e) {
    yield put(roleActions.updateRoleFail(roleId))

    handleError(e)
  }
}
export function* createRole({ role }) {
  yield put(roleActions.createRoleLoading())
  try {
    const { data } = yield call(rolesApiService.post, '/', {
      name: role.roleName,
      country: role.country,
      permissions: role.permissions,
    })
    yield put(roleActions.createRoleSuccess(data))
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('RoleList.RoleSuccessCreated')}
      />,
    )
  } catch (e) {
    yield put(roleActions.createRoleFail())
    handleError(e)
  }
}
export function* deleteRole({ roleId }) {
  yield put(roleActions.deleteRoleLoading(roleId))

  try {
    yield call(rolesApiService.delete, `/${roleId}`)
    yield put(roleActions.deleteRoleSuccess(roleId))
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('RoleList.RoleDeletedSuccessfully')}
      />,
    )
  } catch (e) {
    yield put(roleActions.deleteRoleFail(roleId))
    handleError(e)
  }
}
export function* fetchRoleAdminList({ roleId, offset }) {
  yield put(roleActions.fetchRoleAdminListLoading())

  try {
    const { data } = yield call(rolesApiService.get, `/${roleId}/admins`, {
      params: {
        offset: offset,
      },
    })
    yield put(
      roleActions.fetchRoleAdminListSuccess(
        offset,
        data.admins,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(roleActions.fetchRoleAdminListFail())
    handleError(e)
  }
}
