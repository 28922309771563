import IFFIlterType from 'Enums/IFFilterType'
import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  fetchVouchersListRequestState: RequestState.UNINITIALIZED,
  updateVoucherRequestState: RequestState.UNINITIALIZED,
  deleteVoucherRequestState: RequestState.UNINITIALIZED,
  paginationOffset: 0,
  vouchersSortingAsc: false,
  vouchersFilter: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE,
      title: 'Expires before',
      initialValue: '',
      value: '',
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Validity',
      data: ['Valid', 'Expired'],
      initialValue: [],
      value: [],
    },
  ],
  defaultVouchersFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE,
      title: 'Expires before',
      initialValue: '',
      value: '',
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Validity',
      data: ['Valid', 'Expired'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Country',
      data: ['Egypt', 'Jordan'],
      initialValue: [],
      value: [],
    },
  ],
  vouchers: [],
  vouchersCount: 0,
  fetchVouchersCountRequestState: RequestState.UNINITIALIZED,
}
