import { INITIAL_STATE } from './initialState'
import { UserTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import history from 'history/browser'
import InfinityEnums from 'Enums/InfinityEnums'
import { encodeObjectToBase64 } from '../../Utils/Base64Functions'

export const fetchUserListLoading = (state) => {
  return {
    ...state,
    fetchUsersListRequestState: RequestState.LOADING,
  }
}

export const fetchUserListSuccess = (
  state,
  { offset, users, nextOffset, newUser, index, filter },
) => {
  let result = []
  for (let j = 0; j < users.length; j++) {
    if (!state.users.some((user) => user.id === users[j].id)) {
      result.push(users[j])
    }
  }
  const onUsersPage =
    window.location.pathname.toLocaleLowerCase().indexOf('user') ||
    history.location.pathname.indexOf('user')
  if (onUsersPage !== -1) {
    if (offset == 0) {
      if (users && users.length !== 0) {
        if (newUser !== null) {
          history.push({
            pathname: `/user/${newUser.id}`,
            search: `?filter=${encodeObjectToBase64(filter)}`,
          })
          return {
            ...state,
            users: [...users, newUser],
            paginationOffset: nextOffset,
            fetchUsersListRequestState: RequestState.SUCCEEDED,
            selectedUserIndex: index + 1,
            usersFilters: filter,
          }
        } else {
          let user = users[index]
          history.push({
            pathname: `/user/${user.id}`,
            search: `?filter=${encodeObjectToBase64(filter)}`,
          })
          return {
            ...state,
            users: [...users],
            paginationOffset: nextOffset,
            fetchUsersListRequestState: RequestState.SUCCEEDED,
            selectedUserIndex: index,
            usersFilters: filter,
          }
        }
      } else {
        return {
          ...state,
          users: [...users],
          paginationOffset: nextOffset,
          fetchUsersListRequestState: RequestState.SUCCEEDED,
          selectedUserIndex: -1,
          usersFilters: filter,
        }
      }
    } else {
      let selectedIndex = 0
      const usersList = state.users
      if (usersList.length !== 0) {
        const stateSelectedIndex = state.selectedUserIndex
        const selectedUser = usersList[stateSelectedIndex]
        let userOutOfPlace = false
        if (users[0] && selectedUser.createdAt < users[0]['createdAt'])
          userOutOfPlace = true
        if (userOutOfPlace) {
          const outOfPlaceUserIndex = users.findIndex(
            (user) => user.id === selectedUser.id,
          )
          if (outOfPlaceUserIndex !== -1)
            selectedIndex = usersList.length + outOfPlaceUserIndex - 1
          else selectedIndex = usersList.length + users.length - 1
        } else selectedIndex = state.selectedUserIndex
      }
      let combinedUsers = [...state.users, ...result]
      combinedUsers.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
      return {
        ...state,
        users: combinedUsers,
        paginationOffset: nextOffset,
        fetchUsersListRequestState: RequestState.SUCCEEDED,
        selectedUserIndex: selectedIndex,
      }
    }
  } else {
    return {
      ...state,
      fetchUsersListRequestState: RequestState.UNINITIALIZED,
    }
  }
}

export const fetchUserListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUsersListRequestState: requestState,
  }
}

export const setUserListSelectedIndex = (state, { index }) => {
  return {
    ...state,
    selectedUserIndex: index,
  }
}

export const fetchUserListCountLoading = (state) => {
  return {
    ...state,
    fetchUserListCountRequestState: RequestState.LOADING,
  }
}
export const fetchUserListCountSuccess = (state, { usersCount }) => {
  return {
    ...state,
    usersCount: usersCount,
    fetchUserListCountRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchUserListCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserListCountRequestState: requestState,
  }
}

export const clearUsers = (state) => {
  return {
    ...state,
    users: [],
    paginationOffset: 0,
    fetchUsersListRequestState: RequestState.UNINITIALIZED,
  }
}

export const clearCurrentUser = (state) => {
  return {
    ...state,
    currentUser: {
      id: '',
      chargingTokens: [],
      paymentCards: [],
      balance: 0,
      isActive: true,
      name: '',
      phone: '',
      transactions: [],
      paymentSessions: [],
      selectedCountry: '',
    },
    fetchUserDetailsRequestState: RequestState.UNINITIALIZED,
    fetchUserTransactionListRequestState: RequestState.UNINITIALIZED,
  }
}

export const setUsersFilter = (state, { newFilter }) => {
  return {
    ...state,
    usersFilters: newFilter,
  }
}
export const setSelectedCountry = (state, { newCountry }) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      selectedCountry: newCountry,
    },
  }
}

export const fetchUserDetailsLoading = (state) => {
  return {
    ...state,
    fetchUserDetailsRequestState: RequestState.LOADING,
  }
}

export const fetchUserDetailsSuccess = (state, { user }) => {
  let currentUser = state.currentUser
  return {
    ...state,
    fetchUserDetailsRequestState: RequestState.SUCCEEDED,
    currentUser: {
      ...user,
      chargingTokens: user.chargingTokens.chargingTokens,
      paymentCards: user.paymentCards,
      selectedCountry: user.country.code,
      transactions: currentUser.transactions,
      paymentSessions: currentUser.paymentSessions,
    },
  }
}

export const fetchUserDetailsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserDetailsRequestState: requestState,
  }
}

export const fetchUserTransactionListLoading = (state) => {
  return {
    ...state,
    fetchUserTransactionListRequestState: RequestState.LOADING,
  }
}

export const fetchUserTransactionListSuccess = (
  state,
  { offset, transactions, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchUserTransactionListRequestState: RequestState.SUCCEEDED,
      transactionListPaginationOffset: nextOffset,
      currentUser: {
        ...state.currentUser,
        transactions: [...transactions],
      },
    }
  } else {
    return {
      ...state,
      fetchUserTransactionListRequestState: RequestState.SUCCEEDED,
      transactionListPaginationOffset: nextOffset,
      currentUser: {
        ...state.currentUser,
        transactions: [...state.currentUser.transactions, ...transactions],
      },
    }
  }
}

export const fetchUserTransactionListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserTransactionListRequestState: requestState,
  }
}

export const clearTransactions = (state) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [],
    },
    transactionListPaginationOffset: 0,
    fetchUserTransactionListRequestState: RequestState.UNINITIALIZED,
  }
}

export const setUserTransactionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    transactionsFilters: newFilter,
  }
}
export const setUserTransactionsPaginationOffset = (state, { newOffset }) => {
  return {
    ...state,
    transactionListPaginationOffset: newOffset,
  }
}
export const setUserPaymentsPaginationOffset = (state, { newOffset }) => {
  return {
    ...state,
    paymentSessionListPaginationOffset: newOffset,
  }
}

export const addToUserTransactionsFilter = (state, { admin }) => {
  let newTransactionsFilter = state.transactionsFilters
  if (admin.registeredCountries.length > 1) {
    const countryNames = admin.registeredCountries.map(
      (country) => country.name,
    )
    let index = state.transactionsFilters.findIndex(
      (filter) => filter.title === InfinityEnums.IFFilterTitle.Country,
    )
    if (index == -1) {
      newTransactionsFilter.push({
        type: IFFIlterType.CHECK,
        title: InfinityEnums.IFFilterTitle.Country,
        data: countryNames,
        initialValue: [],
        value: [],
      })
    }
  }
  return {
    ...state,
    transactionsFilters: newTransactionsFilter,
  }
}

export const clearUserTransactionsFilter = (state) => {
  return {
    ...state,
    transactionsFilters: [
      {
        type: IFFIlterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.CHECK,
        title: 'Type',
        data: ['addMoney', 'charge', 'refund'],
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.DATE_RANGE,
        title: 'Date',
        initialValue: [],
        value: [],
      },
    ],
  }
}

export const fetchUserPaymentSessionListLoading = (state) => {
  return {
    ...state,
    fetchUserPaymentSessionListRequestState: RequestState.LOADING,
  }
}

export const fetchUserPaymentSessionListSuccess = (
  state,
  { offset, paymentSessions, nextOffset },
) => {
  if (offset === 0)
    return {
      ...state,
      paymentSessionListPaginationOffset: nextOffset,
      fetchUserPaymentSessionListRequestState: RequestState.SUCCEEDED,
      currentUser: {
        ...state.currentUser,
        paymentSessions: [...paymentSessions],
      },
    }
  else
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        paymentSessions: [
          ...state.currentUser.paymentSessions,
          ...paymentSessions,
        ],
      },
      paymentSessionListPaginationOffset: nextOffset,
      fetchUserPaymentSessionListRequestState: RequestState.SUCCEEDED,
    }
}

export const fetchUserPaymentSessionListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserPaymentSessionListRequestState: requestState,
  }
}

export const clearPaymentSessions = (state) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      paymentSessions: [],
    },
    paymentSessionListPaginationOffset: 0,
    fetchUserPaymentSessionListRequestState: RequestState.UNINITIALIZED,
  }
}
export const setUserPaymentsFilter = (state, { newFilter }) => {
  return {
    ...state,
    paymentsFilters: newFilter,
  }
}
export const addToUserPaymentsFilter = (state, { admin }) => {
  let newPaymentsFilter = state.paymentsFilters
  if (admin.registeredCountries.length > 1) {
    const countryNames = admin.registeredCountries.map(
      (country) => country.name,
    )
    let index = state.paymentsFilters.findIndex(
      (filter) => filter.title === InfinityEnums.IFFilterTitle.Country,
    )
    if (index == -1) {
      newPaymentsFilter.push({
        type: IFFIlterType.CHECK,
        title: InfinityEnums.IFFilterTitle.Country,
        data: countryNames,
        initialValue: [],
        value: [],
      })
    }
  }
  return {
    ...state,
    paymentsFilters: newPaymentsFilter,
  }
}
export const clearUserPaymentsFilter = (state) => {
  return {
    ...state,
    paymentsFilters: [
      {
        type: IFFIlterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.CHECK,
        title: 'Status',
        data: ['pending', 'success', 'fail'],
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.DATE_RANGE,
        title: 'Date',
        initialValue: [],
        value: [],
      },
    ],
  }
}

export const setCurrentUserChargingTokenDeactiveLoading = (
  state,
  { index },
) => {
  state.currentUser.chargingTokens[index].isLoading = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...state.currentUser.chargingTokens],
    },
  }
}

export const setCurrentUserChargingTokenDeactiveSuccess = (
  state,
  { index },
) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.chargingTokens[index].isLoading = false
  updatedCurrentUser.chargingTokens[index].status =
    InfinityEnums.AppChargingTokenStatus.DEACTIVATED
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...updatedCurrentUser.chargingTokens],
    },
  }
}

export const setCurrentUserChargingTokenDeactiveFail = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.chargingTokens[index].isLoading = false
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...state.currentUser.chargingTokens],
    },
  }
}

export const setUserSortingAsc = (state, { isAsc }) => {
  return {
    ...state,
    userSortingAsc: isAsc,
  }
}

export const setSelectedUserIndex = (state, { index }) => {
  return {
    ...state,
    selectedUserIndex: index,
  }
}

export const setCurrentUserTransactionRefundLoading = (state, { index }) => {
  state.currentUser.transactions[index].isLoading = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
  }
}
export const setCurrentUserTransactionRefundSuccess = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[index].isLoading = false
  updatedCurrentUser.transactions[index].isRefunded = true
  const updatedWallets = state.currentUser.groupedWallets.map((wallet) => {
    if (wallet.code === updatedCurrentUser.transactions[index].country.code) {
      return {
        ...wallet,
        balance: wallet.balance - updatedCurrentUser.transactions[index].amount,
      }
    }
    return wallet
  })
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
      groupedWallets: updatedWallets,
    },
  }
}

export const setCurrentUserTransactionRefundFail = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[index].isLoading = false
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
  }
}

export const setCurrentUserNewBalanceSuccess = (state, { transaction }) => {
  let newTransactions = state.currentUser.transactions
  newTransactions.unshift(transaction)
  const updatedWallets = state.currentUser.groupedWallets.map((wallet) => {
    if (wallet.code === transaction.country.code) {
      return {
        ...wallet,
        balance: transaction.isCredit
          ? wallet.balance + transaction.amount
          : wallet.balance - transaction.amount,
      }
    }
    return wallet
  })
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      groupedWallets: updatedWallets,
      isNewBalanceLoading: false,
      transactions: [...state.currentUser.transactions],
    },
  }
}

export const setCurrentUserNewBalanceLoading = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isNewBalanceLoading: true },
  }
}

export const setCurrentUserNewBalanceFail = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isNewBalanceLoading: false },
  }
}

export const addCurrentUserNewCardSuccess = (state, { chargingToken }) => {
  let newChargingTokens = state.currentUser.chargingTokens
  for (let visualNumber of chargingToken) {
    newChargingTokens.push(visualNumber)
  }
  return {
    ...state,
    addCurrentCardRequestState: RequestState.SUCCEEDED,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...newChargingTokens],
      isNewCardLoading: false,
    },
  }
}

export const addCurrentUserNewCardLoading = (state) => {
  return {
    ...state,
    addCurrentCardRequestState: RequestState.LOADING,
    currentUser: {
      ...state.currentUser,
      isNewCardLoading: true,
    },
  }
}

export const addCurrentUserNewCardFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addCurrentCardRequestState: requestState,
    currentUser: {
      ...state.currentUser,
      isNewCardLoading: false,
    },
  }
}

export const toggleCurrentUserIsActiveSuccess = (
  state,
  { id, setIsActive },
) => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i].id === id) {
      state.users[i].isActive = setIsActive
    }
  }

  return {
    ...state,
    users: [...state.users],
    currentUser: {
      ...state.currentUser,
      isActive:
        state.currentUser.id === id ? setIsActive : state.currentUser.isActive,
      isToggleIsActiveLoading: false,
    },
  }
}

export const toggleCurrentUserIsActiveLoading = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isToggleIsActiveLoading: true },
  }
}

export const toggleCurrentUserIsActiveFail = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isToggleIsActiveLoading: false },
  }
}

export const setCurrentUserNewPhoneLoading = (state) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      changePhoneRequestState: RequestState.LOADING,
    },
  }
}

export const setCurrentUserNewPhoneFail = (state, { id }) => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i].id === id) {
      state.users[i].changePhoneRequestState = RequestState.ERROR_400_OCCURRED
    }
  }
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      changePhoneRequestState:
        state.currentUser.id === id
          ? RequestState.ERROR_400_OCCURRED
          : state.currentUser.changePhoneRequestState,
    },
  }
}

export const setCurrentUserNewPhoneSuccess = (state, { id, newPhone }) => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i].id === id) {
      state.users[i].phone = newPhone
      state.users[i].changePhoneRequestState = RequestState.SUCCEEDED
    }
  }
  return {
    ...state,
    users: [...state.users],
    currentUser: {
      ...state.currentUser,
      phone: state.currentUser.id === id ? newPhone : state.currentUser.phone,
      changePhoneRequestState:
        state.currentUser.id === id
          ? RequestState.SUCCEEDED
          : state.currentUser.changePhoneRequestState,
    },
  }
}

export const reverseUserChargeTransactionLoading = (state, { index }) => {
  state.currentUser.transactions[index].isReverseChargeTransactionLoading = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
    reverseChargeTransactionRequestState: RequestState.LOADING,
  }
}

export const reverseUserChargeTransactionSuccess = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[
    index
  ].isReverseChargeTransactionLoading = false
  updatedCurrentUser.transactions[index].status =
    InfinityEnums.TransactionStatus.VOID
  const updatedWallets = state.currentUser.groupedWallets.map((wallet) => {
    if (wallet.code === updatedCurrentUser.transactions[index].country.code) {
      return {
        ...wallet,
        balance:
          updatedCurrentUser.transactions[index].amount > 0
            ? wallet.balance + updatedCurrentUser.transactions[index].amount
            : wallet.balance,
        points:
          updatedCurrentUser.transactions[index].pointsAmount > 0
            ? wallet.points +
              updatedCurrentUser.transactions[index].pointsAmount
            : wallet.points,
      }
    }
    return wallet
  })
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
      groupedWallets: updatedWallets,
    },
    reverseChargeTransactionRequestState: RequestState.SUCCEEDED,
  }
}

export const reverseUserChargeTransactionError = (state, { index, error }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[
    index
  ].isReverseChargeTransactionLoading = false

  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
    reverseChargeTransactionRequestState: requestState,
  }
}

export const fetchUserPointsLoading = (state) => {
  return {
    ...state,
    fetchUserPointsListRequestState: RequestState.LOADING,
  }
}

export const fetchUserPointsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED

  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserPointsListRequestState: requestState,
  }
}

export const fetchUserPointsSuccess = (
  state,
  { offset, points, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchUserPointsListRequestState: RequestState.SUCCEEDED,
      pointsListPaginationOffset: nextOffset,
      points: [...points],
    }
  } else {
    return {
      ...state,
      fetchUserPointsListRequestState: RequestState.SUCCEEDED,
      pointsListPaginationOffset: nextOffset,
      points: [...state.points, ...points],
    }
  }
}

export const clearPoints = (state) => {
  return {
    ...state,
    points: [],
    pointsListPaginationOffset: 0,
  }
}

export const deleteCurrentUserPaymentCardLoading = (state, { index }) => {
  state.currentUser.paymentCards[index].isLoading = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      paymentCards: [...state.currentUser.paymentCards],
    },
  }
}

export const deleteCurrentUserPaymentCardSuccess = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.paymentCards[index].isLoading = false
  updatedCurrentUser.paymentCards = state.currentUser.paymentCards.filter(
    (_, i) => i !== index,
  )
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      paymentCards: [...state.currentUser.paymentCards],
    },
  }
}

export const deleteCurrentUserPaymentCardFail = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.paymentCards[index].isLoading = false
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      paymentCards: [...state.currentUser.paymentCards],
    },
  }
}
export const setAdminActionsPaginationOffset = (state, { newOffset }) => {
  return {
    ...state,
    adminActionsPaginationOffset: newOffset,
  }
}

export const setAdminActionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    adminActionsFilters: newFilter,
  }
}
export const clearAdminActionsFilter = (state) => {
  return {
    ...state,
    adminActionsFilters: [
      {
        type: IFFIlterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.DATE_RANGE,
        title: 'Date',
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.CHECK,
        title: 'Action',
        data: ['Create', 'Edit', 'Delete', 'Activate', 'Deactivate'],
        initialValue: [],
        value: [],
      },
    ],
  }
}

export const fetchAdminActionsLoading = (state) => {
  return {
    ...state,
    fetchAdminActionsRequestState: RequestState.LOADING,
  }
}

export const fetchAdminActionsSuccess = (
  state,
  { offset, adminActions, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchAdminActionsRequestState: RequestState.SUCCEEDED,
      adminActionsPaginationOffset: nextOffset,
      adminActions: [...adminActions],
    }
  } else {
    return {
      ...state,
      fetchAdminActionsRequestState: RequestState.SUCCEEDED,
      adminActionsPaginationOffset: nextOffset,
      adminActions: [...state.adminActions, ...adminActions],
    }
  }
}
export const fetchAdminActionsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminActionsRequestState: requestState,
  }
}
export const clearAdminActions = (state) => {
  return {
    ...state,
    adminActions: [],
    adminActionsPaginationOffset: 0,
    fetchAdminActionsRequestState: RequestState.UNINITIALIZED,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [UserTypes.FETCH_USER_LIST_LOADING]: fetchUserListLoading,
  [UserTypes.FETCH_USER_LIST_SUCCESS]: fetchUserListSuccess,
  [UserTypes.FETCH_USER_LIST_ERROR]: fetchUserListError,
  [UserTypes.SET_USER_LIST_SELECTED_INDEX]: setUserListSelectedIndex,
  [UserTypes.FETCH_USER_LIST_COUNT_LOADING]: fetchUserListCountLoading,
  [UserTypes.FETCH_USER_LIST_COUNT_SUCCESS]: fetchUserListCountSuccess,
  [UserTypes.FETCH_USER_LIST_COUNT_FAIL]: fetchUserListCountFail,
  [UserTypes.CLEAR_USERS]: clearUsers,
  [UserTypes.SET_USERS_FILTER]: setUsersFilter,
  [UserTypes.SET_SELECTED_COUNTRY]: setSelectedCountry,

  [UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_DEACTIVE_LOADING]:
    setCurrentUserChargingTokenDeactiveLoading,
  [UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_DEACTIVE_SUCCESS]:
    setCurrentUserChargingTokenDeactiveSuccess,
  [UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_DEACTIVE_FAIL]:
    setCurrentUserChargingTokenDeactiveFail,
  [UserTypes.SET_USER_SORTING_ASC]: setUserSortingAsc,
  [UserTypes.SET_SELECTED_USER_INDEX]: setSelectedUserIndex,
  [UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND_LOADING]:
    setCurrentUserTransactionRefundLoading,
  [UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND_SUCCESS]:
    setCurrentUserTransactionRefundSuccess,
  [UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND_FAIL]:
    setCurrentUserTransactionRefundFail,
  [UserTypes.SET_CURRENT_USER_NEW_BALANCE_SUCCESS]:
    setCurrentUserNewBalanceSuccess,
  [UserTypes.SET_CURRENT_USER_NEW_BALANCE_LOADING]:
    setCurrentUserNewBalanceLoading,
  [UserTypes.SET_CURRENT_USER_NEW_BALANCE_FAIL]: setCurrentUserNewBalanceFail,
  [UserTypes.ADD_CURRENT_USER_NEW_CARD_LOADING]: addCurrentUserNewCardLoading,
  [UserTypes.ADD_CURRENT_USER_NEW_CARD_SUCCESS]: addCurrentUserNewCardSuccess,
  [UserTypes.ADD_CURRENT_USER_NEW_CARD_FAIL]: addCurrentUserNewCardFail,
  [UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE_LOADING]:
    toggleCurrentUserIsActiveLoading,
  [UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE_FAIL]: toggleCurrentUserIsActiveFail,
  [UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE_SUCCESS]:
    toggleCurrentUserIsActiveSuccess,
  [UserTypes.FETCH_USER_DETAILS_LOADING]: fetchUserDetailsLoading,
  [UserTypes.FETCH_USER_DETAILS_SUCCESS]: fetchUserDetailsSuccess,
  [UserTypes.FETCH_USER_DETAILS_FAIL]: fetchUserDetailsFail,
  [UserTypes.FETCH_USER_TRANSACTION_LIST_LOADING]:
    fetchUserTransactionListLoading,
  [UserTypes.FETCH_USER_TRANSACTION_LIST_SUCCESS]:
    fetchUserTransactionListSuccess,
  [UserTypes.FETCH_USER_TRANSACTION_LIST_FAIL]: fetchUserTransactionListFail,
  [UserTypes.CLEAR_TRANSACTIONS]: clearTransactions,
  [UserTypes.SET_USER_TRANSACTIONS_FILTER]: setUserTransactionsFilter,
  [UserTypes.ADD_TO_USER_TRANSACTIONS_FILTER]: addToUserTransactionsFilter,
  [UserTypes.ADD_TO_USER_PAYMENTS_FILTER]: addToUserPaymentsFilter,
  [UserTypes.SET_USER_PAYMENTS_FILTER]: setUserPaymentsFilter,
  [UserTypes.FETCH_USER_PAYMENT_SESSION_LIST_LOADING]:
    fetchUserPaymentSessionListLoading,
  [UserTypes.FETCH_USER_PAYMENT_SESSION_LIST_SUCCESS]:
    fetchUserPaymentSessionListSuccess,
  [UserTypes.FETCH_USER_PAYMENT_SESSION_LIST_ERROR]:
    fetchUserPaymentSessionListError,
  [UserTypes.CLEAR_PAYMENT_SESSIONS]: clearPaymentSessions,
  [UserTypes.CLEAR_CURRENT_USER]: clearCurrentUser,
  [UserTypes.CLEAR_USER_TRANSACTIONS_FILTER]: clearUserTransactionsFilter,
  [UserTypes.CLEAR_USER_PAYMENTS_FILTER]: clearUserPaymentsFilter,
  [UserTypes.SET_CURRENT_USER_NEW_PHONE_LOADING]: setCurrentUserNewPhoneLoading,
  [UserTypes.SET_CURRENT_USER_NEW_PHONE_SUCCESS]: setCurrentUserNewPhoneSuccess,
  [UserTypes.SET_CURRENT_USER_NEW_PHONE_FAIL]: setCurrentUserNewPhoneFail,
  [UserTypes.REVERSE_USER_CHARGE_TRANSACTION_LOADING]:
    reverseUserChargeTransactionLoading,
  [UserTypes.REVERSE_USER_CHARGE_TRANSACTION_SUCCESS]:
    reverseUserChargeTransactionSuccess,
  [UserTypes.REVERSE_USER_CHARGE_TRANSACTION_ERROR]:
    reverseUserChargeTransactionError,
  [UserTypes.FETCH_USER_POINTS_ERROR]: fetchUserPointsError,
  [UserTypes.FETCH_USER_POINTS_LOADING]: fetchUserPointsLoading,
  [UserTypes.FETCH_USER_POINTS_SUCCESS]: fetchUserPointsSuccess,
  [UserTypes.CLEAR_POINTS]: clearPoints,
  [UserTypes.DELETE_CURRENT_USER_PAYMENT_CARD_LOADING]:
    deleteCurrentUserPaymentCardLoading,
  [UserTypes.DELETE_CURRENT_USER_PAYMENT_CARD_SUCCESS]:
    deleteCurrentUserPaymentCardSuccess,
  [UserTypes.DELETE_CURRENT_USER_PAYMENT_CARD_FAIL]:
    deleteCurrentUserPaymentCardFail,
  [UserTypes.FETCH_ADMIN_ACTIONS_LOADING]: fetchAdminActionsLoading,
  [UserTypes.FETCH_ADMIN_ACTIONS_SUCCESS]: fetchAdminActionsSuccess,
  [UserTypes.FETCH_ADMIN_ACTIONS_ERROR]: fetchAdminActionsError,

  [UserTypes.SET_ADMIN_ACTIONS_FILTER]: setAdminActionsFilter,
  [UserTypes.CLEAR_ADMIN_ACTIONS_FILTER]: clearAdminActionsFilter,

  [UserTypes.CLEAR_ADMIN_ACTIONS]: clearAdminActions,
  [UserTypes.SET_ADMIN_ACTIONS_PAGINATION_OFFSET]:
    setAdminActionsPaginationOffset,
  [UserTypes.SET_USER_TRANSACTIONS_PAGINATION_OFFSET]:
    setUserTransactionsPaginationOffset,
  [UserTypes.SET_USER_PAYMENTS_PAGINATION_OFFSET]:
    setUserPaymentsPaginationOffset,
})
