import IFFilterType from 'Enums/IFFilterType'
import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  loginLoading: false,
  token: null,
  refreshToken: null,
  user: null,
  editingAdminRole: [],
  resetPasswordVerificationRequestState: RequestState.UNINITIALIZED,
  fetchEditingAdminRoleRequestState: RequestState.UNINITIALIZED,
  fetchAdminDetailsRequestState: RequestState.UNINITIALIZED,
  ChangingPasswordRequestState: RequestState.UNINITIALIZED,
  ChangingUserNameRequestState: RequestState.UNINITIALIZED,

  fetchAdminActionsRequestState: RequestState.UNINITIALIZED,
  adminActions: [],
  adminActionPaginationOffset: 0,
  adminActionsFilters: [
    {
      type: IFFilterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFilterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
    {
      type: IFFilterType.CHECK,
      title: 'Action',
      data: ['Create', 'Edit', 'Delete', 'Activate', 'Deactivate'],
      initialValue: [],
      value: [],
    },
  ],
  resetPasswordRequestState: RequestState.UNINITIALIZED,
  resetPasswordEmailID: '',
}
