import UserSelectors from 'Stores/User/Selectors'
import { connect } from 'react-redux'
import UserActions from 'Stores/User/Actions'
import styles from './PaymentSessionList.module.css'
import RequestState from 'Enums/RequestState'
import { IFButton, IFTable } from 'Components'
import { useTranslation } from 'react-i18next'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import InfinityEnums from 'Enums/InfinityEnums'
import { Skeleton } from '@mui/material'
import { JsonViewer } from '@textea/json-viewer'
import ReportIcon from '@mui/icons-material/Report'
import moment from 'moment'

const PaymentSessionList = ({
  paymentSessions,
  paymentsFilters,
  fetchPaymentSessionListRequestState,
  paymentSessionListPaginationOffset,
  userId,
  fetchUserPaymentSessionList,
  tablesHeight,
  selectedUserId,
  loadHeaders,
}) => {
  const { t } = useTranslation()

  const loadMoreData = () => {
    if (
      fetchPaymentSessionListRequestState === RequestState.LOADING ||
      paymentSessionListPaginationOffset === null
    )
      return

    fetchUserPaymentSessionList(
      selectedUserId,
      paymentsFilters,
      paymentSessionListPaginationOffset,
      failedRequest,
    )
  }
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchPaymentSessionListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchPaymentSessionListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchPaymentSessionListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchPaymentSessionListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchPaymentSessionListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchPaymentSessionListRequestState ===
        RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchPaymentSessionListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchPaymentSessionListRequestState])
  const handleRetry = () => {
    fetchUserPaymentSessionList(
      userId,
      paymentsFilters,
      paymentSessionListPaginationOffset,
    )
  }
  const paymentTableColumns = useMemo(
    () => [
      {
        accessorKey: 'ID',
        header: loadHeaders ? (
          <Skeleton variant="text" width="110px" animation="none" height={20} />
        ) : (
          t('UserPage.paymentTableColumns.paymentID')
        ),
        Cell: ({ row }) => {
          return row.index < paymentSessions.length ? (
            <div className={styles.ColumnTitle}>
              <ExpandMoreIcon
                className={styles.ExpandMoreIcon}
                style={{
                  transform: row.getIsExpanded()
                    ? 'rotate(0deg)'
                    : 'rotate(-90deg)',
                }}
              />
              <div className={styles.TransactionName}>
                <IFText>{row.original.id}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <div className={styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="80%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'amount',
        header: loadHeaders ? (
          <Skeleton variant="text" width="110px" animation="none" height={20} />
        ) : (
          t('UserPage.paymentTableColumns.Amount')
        ),
        Cell: ({ row }) => {
          return row.index < paymentSessions.length ? (
            <IFText>
              {row.original.country?.currency?.symbol} {row.original.amount}
            </IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="30%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'status',
        header: loadHeaders ? (
          <Skeleton variant="text" width="110px" animation="none" height={20} />
        ) : (
          t('UserPage.paymentTableColumns.Status')
        ),
        Cell: ({ row }) => {
          const status = row.original.status
          let color

          switch (status) {
            case InfinityEnums.PaymentStatus.SUCCEED:
              color = Colors.green
              break
            case InfinityEnums.PaymentStatus.PENDING:
              color = Colors.PaymentPendingStatus
              break
            case InfinityEnums.PaymentStatus.FAILED:
              color = Colors.red
              break
            default:
              color = Colors.gray
          }

          return row.index < paymentSessions.length ? (
            <div
              className={styles.Status}
              style={{
                backgroundColor: Colors.Status,
              }}
            >
              <IFText style={{ color: color }}>{status}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="45%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'startedAt',
        header: loadHeaders ? (
          <Skeleton variant="text" width="110px" animation="none" height={20} />
        ) : (
          t('UserPage.paymentTableColumns.ProcessedOn')
        ),
        Cell: ({ row }) => {
          return row.index < paymentSessions.length ? (
            <IFText>
              {moment(row.original.createdAt).format('MMM DD, YYYY LTS')}
            </IFText>
          ) : failedRequest ? (
            <div className={styles.IconDiv}>
              <IFButton
                size="sm"
                color={Colors.red}
                text={t('IFTable.TryAgain')}
                onClick={() => {
                  handleRetry()
                }}
              />
            </div>
          ) : (
            <Skeleton variant="text" width="70%" animation="none" height={20} />
          )
        },
      },
    ],
    [paymentSessions, failedRequest, loadHeaders],
  )
  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  const renderDetailPanel = ({ row }) => (
    <div className={styles.DetailPanel} onClick={(e) => e.stopPropagation()}>
      <IFText
        style={{ color: Colors.text }}
        className={styles.DetailsContainer}
      >
        {t('PaymentSession.Reason')} {row.original.reason}
      </IFText>
      <IFText
        style={{ color: Colors.text }}
        className={styles.DetailsContainer}
      >
        <JsonViewer
          value={row.original.details}
          displayDataTypes={false}
          defaultInspectDepth={0}
        />
      </IFText>
    </div>
  )

  return (
    <div className={styles.tables}>
      {((fetchPaymentSessionListRequestState !== RequestState.SUCCEEDED &&
        !paymentSessionListPaginationOffset) ||
        paymentSessions) && (
        <IFTable
          columns={paymentTableColumns}
          data={paymentSessions}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
          paginationOffset={paymentSessionListPaginationOffset}
          filters={paymentsFilters}
          showSkeleton={
            fetchPaymentSessionListRequestState === RequestState.LOADING &&
            paymentSessionListPaginationOffset === 0
          }
          renderDetailPanel={renderDetailPanel}
          tableMaxHeight={tablesHeight}
          tableSetHeight={'100%'}
          tableRequestState={fetchPaymentSessionListRequestState}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserPaymentSessionList: (userId, filter, offset, shouldShowError) =>
      dispatch(
        UserActions.fetchUserPaymentSessionList(
          userId,
          filter,
          offset,
          shouldShowError,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  selectedUserId: UserSelectors.getSelectedUserId(state),
  paymentSessions: UserSelectors.getPaymentSessionList(state),
  paymentsFilters: UserSelectors.getPaymentsFilters(state),
  paymentSessionListPaginationOffset:
    UserSelectors.getPaymentSessionListPaginationOffset(state),
  fetchPaymentSessionListRequestState:
    UserSelectors.getFetchUserPaymentSessionListRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSessionList)
